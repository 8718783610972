import { FC } from "react";
import { Box } from "@mui/material";
import { Link } from "@mui/material";
import IconLinkedin from "../../assets/images/icons/LinkedIn.svg";
import IconTwitter from "../../assets/images/icons/Twitter.svg";

interface Props {
  inMobile?: boolean;
}

const SocialLinks: FC<Props> = ({ inMobile = false }) => {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box sx={{ padding: inMobile ? "0 1.25rem" : "0 2.25rem" }}>
        <Link href="https://twitter.com/bitadata" target="_blank">
          <img src={IconTwitter} alt="Twitter" />
        </Link>
      </Box>
      <Box sx={{ padding: inMobile ? "0 1.25rem" : "0 2.25rem" }}>
        <Link href="https://www.linkedin.com/company/bita-gmbh" target="_blank">
          <img src={IconLinkedin} alt="LinkedIn" />
        </Link>
      </Box>
    </Box>
  );
};

export default SocialLinks;
