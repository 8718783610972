import { ChangeEventHandler, FC } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  FormControl,
  FormControlProps,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { useStyles } from "./styles";

interface Props extends FormControlProps {
  label: string;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  onChange: ChangeEventHandler<HTMLInputElement>;
  colorScheme?: "black" | "white";
}

const SearchInput: FC<Props> = ({
  label,
  variant = "standard",
  placeholder,
  onChange,
  colorScheme = "black",
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <FormControl
      className={colorScheme === "white" ? classes.white : ""}
      variant={variant}
      sx={{ minWidth: 240 }}
      {...restProps}
    >
      <InputLabel>{label}</InputLabel>
      <Input
        startAdornment={
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        }
        placeholder={placeholder}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default SearchInput;
