import Img1 from "../../assets/images/products/thematic-data/index1.png";
import Img2 from "../../assets/images/products/thematic-data/index2.png";
import Img3 from "../../assets/images/products/thematic-data/index3.png";
import Img4 from "../../assets/images/products/thematic-data/index4.png";
import Carousel from "../Carousel";
import ThematicIndexesCarouselCard from "./Card";

const ThematicIndexesCarousel = () => {
  return (
    <Carousel options={{ infinite: true }}>
      <ThematicIndexesCarouselCard
        image={Img1}
        type="Equity Index"
        title="BITA Gaming and E-Sports Giants EW Index"
        link="/index/BGAMGE"
      />
      <ThematicIndexesCarouselCard
        image={Img2}
        type="Equity Index"
        title="BITA Airlines and Airports Giants Index"
        link="/index/BNAAGI"
      />
      <ThematicIndexesCarouselCard
        image={Img3}
        type="Equity Index"
        title="BITA NFT and Blockchain Giants Index"
        link="/index/BNFTBG"
      />
      <ThematicIndexesCarouselCard
        image={Img4}
        type="Equity Index"
        title="BITA Green Energy Giants Index"
        link="/index/BNGEGI"
      />
      <ThematicIndexesCarouselCard
        image={Img1}
        type="Equity Index"
        title="BITA Gaming and E-Sports Giants EW Index"
        link="/index/BGAMGE"
      />
      <ThematicIndexesCarouselCard
        image={Img2}
        type="Equity Index"
        title="BITA Airlines and Airports Giants Index"
        link="/index/BNAAGI"
      />
      <ThematicIndexesCarouselCard
        image={Img3}
        type="Equity Index"
        title="BITA NFT and Blockchain Giants Index"
        link="/index/BNFTBG"
      />
      <ThematicIndexesCarouselCard
        image={Img4}
        type="Equity Index"
        title="BITA Green Energy Giants Index"
        link="/index/BNGEGI"
      />
    </Carousel>
  );
};

export default ThematicIndexesCarousel;
