import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(6),
    },
  },
  selectorsRow: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6),
    },
  },
  selectorsContainer: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  selectContainer: {
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingRight: 0,
    },
  },
  searchContainer: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingBottom: "2rem",
    },
  },
  withBorderBottom: {
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
}));
