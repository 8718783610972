import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNewsList } from "../../services/news";
import { News } from "../../types/news";
import { RootState } from "../store";

export interface NewsState {
  list: News[];
  isLoading: boolean;
  error: any;
}

const initialState: NewsState = {
  list: [],
  isLoading: false,
  error: null,
};

// Thunks
export const fetchNewsList = createAsyncThunk(
  "assets/news/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getNewsList();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNewsList.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchNewsList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload?.data;
      state.error = null;
    });
    builder.addCase(fetchNewsList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });
  },
});

export const selectNewsList = (state: RootState) =>
  state.news.list.filter((item) => item.status === "A");

export default newsSlice.reducer;
