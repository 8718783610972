import { FC } from "react";
import { useParams } from "react-router-dom";

const PdfFilePage: FC<{}> = () => {
  const { folder, filename } = useParams();

  if (!folder || !filename) return null;

  const pdfSrc =
    folder === "policies"
      ? `/pdfs/${filename}`
      : `https://files.bita.io/${filename}`;

  return (
    <iframe
      src={pdfSrc}
      width="100%"
      height="100vh"
      title={filename}
      style={{ height: "100vh" }}
    />
  );
};

export default PdfFilePage;
