/*
Exports a store that easily persists and retrieves the state between sessions.
More on this: https://dev.to/dawnind/persist-redux-state-with-redux-persist-3k0d
*/

import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
//   createMigrate,
// } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { customMiddleware } from "./middlewares";
// import { migrations } from "./migrations";
import announcements from "./slices/announcements";
import assets from "./slices/assets";
import auth from "./slices/auth";
import indexes from "./slices/indexes";
import news from "./slices/news";

// const migration = createMigrate(migrations, { debug: false });

// const persistConfig = {
//   key: "root",
//   storage,
//   version: 20230912,
//   migrate: migration,
//   blacklist: ["auth"],
// };

// const persistedReducer = persistReducer(
//   persistConfig,
//   combineReducers({
//     auth,
//     indexes,
//     announcements,
//     assets,
//     news,
//   })
// );

// Without using persistReducer
const rootReducer = combineReducers({
  auth,
  indexes,
  announcements,
  assets,
  news,
});

export const store = configureStore({
  // reducer: persistedReducer, // No need to persist for test purposes
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }).concat(customMiddleware),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(customMiddleware),
});

// No need to persist for test purposes
// export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
