import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { authAnalyzer } from "../../services";
import { RootState } from "../store";

export interface AuthState {
  userId: string;
  token: string;
  analyzerToken: string;
}

const initialState: AuthState = {
  userId: "",
  token: "",
  analyzerToken: "",
};

export const getAnalyzerToken = createAsyncThunk(
  "auth/analyzerToken/get",
  async (
    { username, password }: { username: string; password: string },
    thunkAPI
  ) => {
    try {
      const data = await authAnalyzer(username, password);
      return { data };
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnalyzerToken.pending, (state, action) => {});
    builder.addCase(getAnalyzerToken.fulfilled, (state, action) => {
      state.analyzerToken = action.payload.data.access_token;
    });
    builder.addCase(getAnalyzerToken.rejected, (state, action) => {
      state.analyzerToken = "";
    });
  },
});

export const { setToken, setUserId } = authSlice.actions;

export const selectUserId = (state: RootState) => state.auth.userId;
export const selectToken = (state: RootState) => state.auth.token;
export const selectAnalyzerToken = (state: RootState) =>
  state.auth.analyzerToken;

export default authSlice.reducer;
