import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PageHero from "../../components/PageHero";
import Table from "../../components/Table";
import { Column } from "../../types/ui";
import { useStyles } from "../Legal/styles";
import { BUSINESS_DAYS, WEEKENDS } from "./data";

export const TableColumns: Column[] = [
  {
    key: "time",
    label: "Time",
    sortable: false,
  },
  {
    key: "contact_channel",
    label: "Contact channel",
  },
  {
    key: "email",
    label: "Email",
    renderCell: (data) =>
      data.email.map((email: string) => <Box>{email},</Box>),
  },
  {
    key: "phone",
    label: "Phone",
    renderCell: (data) => data.phone.map((p: string) => <Box>{p}</Box>),
  },
];

const SlaPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <PageHero
        colorScheme="light_blue"
        title="Calculation Service Level Agreement"
      />
      <Container className={classes.main}>
        <Box className={classes.section}>
          <Box className={classes.sectionHeading}>Service Commitment</Box>
          <Box className={classes.text}>
            At BITA, we are dedicated to offering around-the-clock support for
            any troubleshooting service. Our Operations and IT teams are located
            in Europe, Asia, and Latin America, and we prioritize timely
            responses to all client inquiries as outlined in our Client Support
            Coverage section.
          </Box>
          <Box className={classes.text}>
            We will make every reasonable effort to ensure that index
            calculation and dissemination are available with a monthly uptime
            percentage of at least 99.95%.
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box className={classes.sectionHeading}>Definitions</Box>
          <Box className={classes.text}>
            <List>
              <ListItem disableGutters dense sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "1.5rem", paddingTop: 0.5 }}>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: 20 }}
                  sx={{ margin: 0 }}
                >
                  The <b>Operations Team</b> is responsible for monitoring the
                  accurate calculation of indexes and cryptocurrencies. They
                  also detect any issues that arise and respond to client
                  requests.
                </ListItemText>
              </ListItem>
              <ListItem disableGutters dense sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "1.5rem", paddingTop: 1 }}>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: 20 }}>
                  The <b>IT Team</b> troubleshoots issues after they are
                  detected.
                </ListItemText>
              </ListItem>
              <ListItem disableGutters dense sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "1.5rem", paddingTop: 1 }}>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: 20 }}>
                  To calculate the <b>Monthly Uptime Percentage</b>, deduct the
                  percentage of continuous 15-second periods of Downtime from
                  100%. The percentage measurements should not include any
                  downtime that resulted directly or indirectly from the SLA
                  Exclusions.
                </ListItemText>
              </ListItem>
              <ListItem disableGutters dense sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "1.5rem", paddingTop: 1 }}>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: 20 }}>
                  When an instrument's price data is not disseminated for more
                  than 15 seconds, it is referred to as <b>downtime</b>. This
                  can only occur when there is sufficient incoming market data
                  to determine a new price.
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box className={classes.sectionHeading}>Client Support Coverage</Box>
          <Box className={classes.text}>
            BITA offers full-time support. Both Operations and IT teams are
            readily available for monitoring and solving issues. The average
            response time of the Operations team is 5 minutes or less. The IT
            average troubleshooting time is 15 minutes after the issue is
            detected.
          </Box>
          <Box className={classes.text}>
            Below, you will find our contact information. All the times are
            specified in Central European Time (CET).
          </Box>

          <Box className={classes.sectionSubHeading}>Business Days</Box>
          <Box>
            <Table
              data={BUSINESS_DAYS}
              columns={TableColumns}
              dense
              hasPagination={false}
            />
          </Box>

          <Box className={classes.sectionSubHeading}>Weekends</Box>
          <Box>
            <Table
              data={WEEKENDS}
              columns={TableColumns}
              dense
              hasPagination={false}
            />
          </Box>
        </Box>

        <Box className={classes.section}>
          <Box className={classes.sectionHeading}>SLA Exclusions</Box>
          <Box className={classes.text}>
            <List>
              <ListItem disableGutters dense sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "1.5rem", paddingTop: 1 }}>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: 20 }}>
                  Multizone downtimes from Amazon Web Services on their Ohio
                  (us-east-2) data center.
                </ListItemText>
              </ListItem>
              <ListItem disableGutters dense sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "1.5rem", paddingTop: 1 }}>
                  <FiberManualRecordIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: 20 }}>
                  If BITA's client does not have a license agreement with a
                  specific exchange that requires one, the exchange may stop
                  providing data to our system. It's important to note that the
                  SLA does not cover any potential downtime that may result.
                </ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SlaPage;
