import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    borderBottom: "1px solid rgba(0, 0, 0, 0.25)",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  imgWrapper: {
    maxWidth: "60%",
    height: theme.spacing(12),
    paddingBottom: theme.spacing(3),

    "& img": {
      height: "100%",
    },
  },
  text: {
    flex: 1,
  },
}));
