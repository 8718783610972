import { FC, MouseEvent } from "react";
import { Download } from "@mui/icons-material";
import { Box } from "@mui/material";
import { News } from "../../types/news";
import { useStyles } from "./styles";

interface Props {
  data: News;
}

const CaseStudyCard: FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { link_media, download_file, categorization, preview_image, title } =
    data;

  const handleClick = () => {
    if (!link_media) return;
    window.open(link_media, "_blank");
  };

  const handleDownload = (e: MouseEvent) => {
    e.stopPropagation();
    if (!download_file) return;
    window.open(download_file, "_blank");
  };

  return (
    <Box
      className={classes.container}
      onClick={handleClick}
      sx={{
        backgroundImage: `url(${preview_image})`,
        cursor: link_media ? "pointer" : "auto",
      }}
    >
      <Box className={classes.header}>
        {categorization?.name ?? "Unknown Type"}
      </Box>
      <Box className={classes.body}>{title}</Box>
      <Box className={classes.footer}>
        {download_file && (
          <Box className={classes.download} onClick={handleDownload}>
            Download <Download />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CaseStudyCard;
