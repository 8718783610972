import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {
    backgroundColor: "#0D0F17",
    color: "#fff",
  },
  hero: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  heroMain: {
    zIndex: 1,
    position: "relative",
    flex: 1,
    display: "flex",
    alignItems: "center",
    paddingLeft: "4rem",
    paddingTop: "12.5rem",
    paddingBottom: "12.5rem",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  videoWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    overflow: "hidden",

    "& video": {
      maxWidth: "none",
      minWidth: "100%",
      minHeight: "100%",
      width: "auto",
      height: "auto",
    },
  },
  videoBackgroundTop: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    right: 0,
    height: "30vh",
    background:
      "linear-gradient(180deg, #0D0F17 20.94%, rgba(13, 15, 23, 0) 71.35%)",
  },
  videoBackground: {
    position: "absolute",
    width: "100%",
    top: "70vh",
    bottom: 0,
    left: 0,
    right: 0,
    height: "30vh",
    background:
      "linear-gradient(180deg, rgba(13, 15, 23, 0) 10.94%, #0D0F17 71.35%)",
  },
  heroSub: {
    position: "relative",
    padding: "4rem 0",
  },
  sections: {
    padding: "0",

    [theme.breakpoints.down("md")]: {
      paddingTop: "4rem",
    },
  },
  newsTitle: {
    fontSize: 48,
    fontWeight: 600,
    padding: "3rem 1rem",
  },
  carouselContainer: {},
  cardsContainer: {},
  trustedBy: {
    paddingBottom: "1.25rem",
    fontSize: 45,
    fontWeight: 600,
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
