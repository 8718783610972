import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: "#fff",
    padding: "0.25rem 0.5rem",
    fontSize: 11,
    fontWeight: 600,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  buttonLabel: {
    paddingLeft: "0.75rem",
    paddingRight: "0.75rem",
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      fontSize: 11,
      lineHeight: 1.2,
    },
  },
}));
