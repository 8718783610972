import { Box, Grid, Container } from "@mui/material";
import HeadquarterCard from "../../components/HeadquarterCard";
import HubspotContactForm from "../../components/HubspotContactForm";
import PageHero from "../../components/PageHero";
import { HEADQUARTERS } from "./data";
import { useStyles } from "./styles";

const ContactUs = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <PageHero colorScheme="dark_blue" title="Contact Us" />
      <Container sx={{ paddingTop: 10, paddingBottom: 8 }}>
        <Grid container spacing={8}>
          <Grid item xs={12} md={8}>
            <Box>
              <Box
                sx={{
                  fontSize: 50,
                  lineHeight: 1.2,
                  fontWeight: 600,
                  color: "#25246d",
                }}
              >
                Contact Us
              </Box>
              <Box
                pt={4}
                sx={{
                  fontSize: 25,
                  lineHeight: 1.2,
                  fontWeight: 400,
                  color: "#4B5158",
                }}
              >
                For any inquiries or requests please fill out the following
                form. Our team will get in touch as soon as possible.
              </Box>

              <Box py={{ xs: 4, md: 8 }}>
                <HubspotContactForm />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              pb={4}
              sx={{
                fontSize: 50,
                lineHeight: 1.2,
                fontWeight: 600,
                color: "#25246d",
              }}
            >
              Locations
            </Box>
            <Box>
              {HEADQUARTERS.map((item) => (
                <HeadquarterCard key={item.id} {...item} />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default ContactUs;
