import { FC } from "react";
import { Box } from "@mui/material";
import GifSpinner from "../../assets/images/icons/spinner-2.gif";
import { useStyles } from "./styles";

interface Props {
  size?: number;
}

const LoadingSpinner: FC<Props> = ({ size = 120 }) => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <img src={GifSpinner} alt="Loading..." width={size} height={size} />
    </Box>
  );
};

export default LoadingSpinner;
