import { FC } from "react";
import { Grid } from "@mui/material";
import TeamMember from "../TeamMember";
import { TEAM_DATA } from "./data";
interface Props {
  inMobile: boolean;
}

const Team: FC<Props> = ({ inMobile }) => {
  return (
    <Grid container spacing={6} justifyContent="space-evenly">
      {TEAM_DATA.map((data) => (
        <Grid key={data.id} item xs={12} md={4}>
          <TeamMember {...data} inMobile={inMobile} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Team;
