import { useState, MouseEvent, FC, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IMenuItem } from "../../types/ui";
import { useStyles } from "./styles";

interface Props {
  label: string;
  items: IMenuItem[];
}

const HeaderMenu: FC<Props> = ({ label, items }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (href?: string) => {
    handleClose();

    if (!href) return;

    if (href.includes("://")) {
      window.open(href, "_blank");
    } else {
      navigate(href);
    }
  };

  // Prevent scrolling with menu open
  useEffect(() => {
    const handleScroll = () => {
      handleClose();
    };

    if (document) {
      document.addEventListener("scroll", handleScroll);
    }

    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <Button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className={classes.menuButton}
        onClick={handleClick}
      >
        {label}
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Menu
        className={classes.menu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        disableScrollLock
      >
        {items.map(({ id, label, href, hidden }) =>
          hidden ? null : (
            <MenuItem
              key={id}
              className={classes.menuItem}
              onClick={() => handleClickItem(href)}
            >
              {label}
            </MenuItem>
          )
        )}
      </Menu>
    </div>
  );
};

export default HeaderMenu;
