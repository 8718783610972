import IconAvatrade from "../../assets/images/home/avatrade.svg";
import IconCirca5000 from "../../assets/images/home/circa5000.svg";
import IconETF from "../../assets/images/home/etf.svg";
import IconFlowBank from "../../assets/images/home/flowbank.svg";
import IconGlobalx from "../../assets/images/home/globalx.svg";
import IconGranite from "../../assets/images/home/graniteshares.svg";
import IconIClima from "../../assets/images/home/iclima.svg";
import IconImpak from "../../assets/images/home/impak.svg";
import IconLeverageShares from "../../assets/images/home/leverageshares.svg";
import IconMelanion from "../../assets/images/home/melanion.svg";
import IconPepperstone from "../../assets/images/home/pepperstone.svg";
import IconPlus500 from "../../assets/images/home/plus500.svg";
import IconQuantumrock from "../../assets/images/home/quantumrock.svg";
import IconRexShares from "../../assets/images/home/rexshares.svg";
import IconSix from "../../assets/images/home/six.svg";
import IconStoneX from "../../assets/images/home/stonex.svg";
import IconSubtract from "../../assets/images/home/subtract.svg";
import IconTematicaResearch from "../../assets/images/home/tematicaresearch.svg";
import IconTMX from "../../assets/images/home/tmx.svg";
import IconXm from "../../assets/images/home/xm.svg";

export const CLIENT_LIST = [
  {
    id: 0,
    label: "Six",
    image: IconSix,
  },
  {
    id: 1,
    label: "Plus500",
    image: IconPlus500,
  },
  {
    id: 2,
    label: "GlobalX",
    image: IconGlobalx,
  },
  {
    id: 3,
    label: "TMX",
    image: IconTMX,
  },
  {
    id: 4,
    label: "IG",
    image: IconSubtract,
  },
  {
    id: 5,
    label: "StoneX",
    image: IconStoneX,
  },
  {
    id: 6,
    label: "PepperStone",
    image: IconPepperstone,
  },
  {
    id: 7,
    label: "AvaTrade",
    image: IconAvatrade,
  },
  {
    id: 8,
    label: "XM",
    image: IconXm,
  },
  {
    id: 9,
    label: "iClima",
    image: IconIClima,
  },
  {
    id: 10,
    label: "Circa5000",
    image: IconCirca5000,
  },
  {
    id: 11,
    label: "ETF",
    image: IconETF,
  },
  {
    id: 12,
    label: "Granite Shares",
    image: IconGranite,
  },
  {
    id: 13,
    label: "Malanion Capital",
    image: IconMelanion,
  },
  {
    id: 14,
    label: "impak",
    image: IconImpak,
  },
  {
    id: 15,
    label: "Leverage Shares",
    image: IconLeverageShares,
  },
  {
    id: 16,
    label: "Flow Bank",
    image: IconFlowBank,
  },
  {
    id: 17,
    label: "Tematica Research",
    image: IconTematicaResearch,
  },
  {
    id: 18,
    label: "Rex Shares",
    image: IconRexShares,
  },  
  {
    id: 19,
    label: "QuantumRock",
    image: IconQuantumrock,
  },
];

export const CLIENT_LIST_DESKTOP = [
  [
    [
      {
        id: 0,
        label: "Six",
        image: IconSix,
      },
      {
        id: 1,
        label: "Plus500",
        image: IconPlus500,
      },
      {
        id: 2,
        label: "GlobalX",
        image: IconGlobalx,
      },
      {
        id: 3,
        label: "TMX",
        image: IconTMX,
      },
      {
        id: 4,
        label: "IG",
        image: IconSubtract,
      },
    ],
    [
      {
        id: 5,
        label: "StoneX",
        image: IconStoneX,
      },
      {
        id: 6,
        label: "PepperStone",
        image: IconPepperstone,
      },
      {
        id: 7,
        label: "AvaTrade",
        image: IconAvatrade,
      },
      {
        id: 8,
        label: "XM",
        image: IconXm,
      },
      {
        id: 9,
        label: "iClima",
        image: IconIClima,
      },
    ],
  ],
  [
    [
      
      {
        id: 10,
        label: "Circa5000",
        image: IconCirca5000,
      },
      {
        id: 11,
        label: "ETF",
        image: IconETF,
      },
      {
        id: 12,
        label: "Granite Shares",
        image: IconGranite,
      },
      {
        id: 13,
        label: "Malanion Capital",
        image: IconMelanion,
      },
      {
        id: 14,
        label: "impak",
        image: IconImpak,
      },
    ],
    [
      {
        id: 15,
        label: "Leverage Shares",
        image: IconLeverageShares,
      },
      {
        id: 16,
        label: "Flow Bank",
        image: IconFlowBank,
      },
      {
        id: 17,
        label: "Tematica Research",
        image: IconTematicaResearch,
      },
      {
        id: 18,
        label: "Rex Shares",
        image: IconRexShares,
      },
      {
        id: 19,
        label: "QuantrumRock",
        image: IconQuantumrock,
      },
    ],
  ]
];
