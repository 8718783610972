import { DrawdownData, IndexData } from "../types/indexes";

export const formatAsTwoDigits = (input: number): string => {
  if (input < 10) return `0${input}`;
  return input.toString();
};

export const formatAsTwoDecimals = (input: number): number => {
  if (Number.isNaN(input)) {
    return input;
  }
  return parseFloat(input.toFixed(2));
};

// Local function
const isDrawdownData = (input: any): input is DrawdownData => (
  typeof input === "object" &&
  input !== null &&
  "max_drawdown" in input &&
  typeof input.max_drawdown === "number"
);

export const formatPercentage = (input: number | string | DrawdownData | IndexData): string => {
  try {
    if (typeof input === "string") return input;
    if (typeof input === "number") return `${(input * 100).toFixed(2)}%`;
    
    // Handle DrawdownData case
    if (isDrawdownData(input)) {
      return `${(input.max_drawdown * 100).toFixed(2)}%`;
    }
    
    // Handle IndexData case
    if (typeof input === "object" && "max" in input && isDrawdownData(input.max)) {
      return `${(input.max.max_drawdown * 100).toFixed(2)}%`;
    }
    return "N/A";
  } catch (error) {
    console.error("Error in formatPercentage:", error);
    return "N/A";
  }
};

export const isNumeric = (value: any) => {
  return typeof value === "number" && !isNaN(value);
};
