import { FC } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Link } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  title: string;
  text: string;
  link?: string;
}

const PortfolioApiCard: FC<Props> = ({ title, text, link }) => {
  const classes = useStyles();

  return (
    <Box className={classes.section}>
      <Box className={classes.sectionTitle}>{title}</Box>
      <Box className={classes.sectionTitleBottomLine}></Box>
      <Box className={classes.sectionText}>{text}</Box>
      <Box className={classes.linkWrapper}>
        {link ? (
          <Link href={link} className={classes.link} target="_blank">
            Documentation
            <Box className={classes.linkIcon}>
              <KeyboardArrowRightIcon htmlColor="#fff" />
            </Box>
          </Link>
        ) : (
          <Box className={classes.comingSoon}>Coming Soon</Box>
        )}
      </Box>
    </Box>
  );
};

export default PortfolioApiCard;
