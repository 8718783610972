import { FC } from "react";
import { Box } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  id: number;
  img: any;
  text: string;
}

const ClientCard: FC<Props> = ({ id, img, text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.imgWrapper}>
        <img src={img} alt={`Thumb for ${id}`} />
      </Box>
      <Box className={classes.text}>{text}</Box>
    </Box>
  );
};

export default ClientCard;
