import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  text: {
    maxWidth: theme.spacing(90),
    fontSize: 20,
    lineHeight: 1.2,
  },
  main: {
    padding: "3rem 6rem",
  },
  mainHeading: {
    ...pageStyles(theme).mainHeading,
    color: theme.palette.primary.main,
  },
  tableContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  selectorsRow: {
    display: "flex",
    flexWrap: "wrap-reverse",
  },
  selectorsContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    paddingBottom: theme.spacing(3),
  },
  selectContainer: {
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingRight: 0,
    },
  },
  searchContainer: {
    marginLeft: "auto",

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  },
  resetBtnContainer: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
  },
  legalContainer: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
  },
  withBorderBottom: {
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  announcementsContainer: {
    paddingBottom: "3rem",
    borderBottom: "1px solid #235CFF",
  },
}));
