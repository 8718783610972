import { Box } from "@mui/material";
import { DownloadLink } from "../../types/ui";
import ExternalLink from "../ExternalLink";
import IndexLabDownloadLink from "../IndexLabDownloadLink";
import { useStyles } from "./styles";

interface Props {
  title: string;
  description?: string;
  downloadLinks: DownloadLink[];
  size?: "md" | "lg";
  bgImg: string;
}

const IndexLabCard: React.FC<Props> = ({
  title,
  description,
  downloadLinks,
  bgImg,
  size = "md",
}) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.root} ${size === "lg" ? classes.lg : classes.md}`}
      sx={{
        backgroundImage: `url(${encodeURI(bgImg)})`,
      }}
    >
      <Box className={classes.label}>{title}</Box>
      <Box className={classes.description}>{description}</Box>
      <Box className={classes.footer}>
        <IndexLabDownloadLink downloadLinks={downloadLinks} />
        <ExternalLink label="Contact Sales" link="/contact-us" noMargin />
      </Box>
    </Box>
  );
};

export default IndexLabCard;
