import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    paddingBottom: 0,

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      paddingBottom: "1.5rem",
    },
  },
  button: {
    "&.MuiButton-root": {
      minWidth: "6rem",
      marginLeft: "1px",
      marginRight: "1px",
      marginBottom: 0,
      paddingTop: "0.75rem",
      paddingBottom: "0.75rem",
      borderRadius: 0,
      lineHeight: 1,
      fontWeight: 600,
      boxShadow: "none",

      [theme.breakpoints.down("md")]: {
        marginBottom: "1rem",
      },

      "&:first-child": {
        borderTopLeftRadius: "0.5rem",
        borderBottomLeftRadius: "0.5rem",
      },
      "&:last-child": {
        borderTopRightRadius: "0.5rem",
        borderBottomRightRadius: "0.5rem",
      },
    },
  },
}));
