import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAnnouncementCategories, getAnnouncements } from "../../services";
import { Announcement, AnnouncementCategory } from "../../types/announcements";
import { RootState } from "../store";

export interface AnnouncementsState {
  list: Announcement[];
  categories: AnnouncementCategory[];
  isLoading: boolean;
  error: any;
}

const initialState: AnnouncementsState = {
  list: [],
  categories: [],
  isLoading: false,
  error: null,
};

// Thunks
export const fetchAnnouncements = createAsyncThunk(
  "announcements/list/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getAnnouncements();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchAnnouncementCategories = createAsyncThunk(
  "announcements/categories/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getAnnouncementCategories();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const announcementsSlice = createSlice({
  name: "announcements",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAnnouncements.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAnnouncements.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload?.data;
    });
    builder.addCase(fetchAnnouncements.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });

    builder.addCase(fetchAnnouncementCategories.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAnnouncementCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action.payload?.data;
    });
    builder.addCase(fetchAnnouncementCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });
  },
});

export const selectAnnoucements = (state: RootState) =>
  state.announcements.list;
export const selectAnnoucementCategories = (state: RootState) =>
  state.announcements.categories;

export default announcementsSlice.reducer;
