import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    color: "#fff",
  },
  mainTitle: {
    fontSize: 30,
    fontWeight: 600,
    textTransform: "uppercase",
  },
  mainTitleBorder: {
    width: theme.spacing(8),
    height: 2,
    backgroundColor: "#C2A633",
    margin: "0.5rem 0",
  },
  mainDescription: {
    fontSize: 20,
  },
  chart: {
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: theme.spacing(40),
    color: "#fff",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  chartMain: {
    position: "absolute",
    inset: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(3),
  },
  chartTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  chartContainer: {
    width: "calc(100%+4rem)",
    marginLeft: theme.spacing(-4),
    marginRight: theme.spacing(-4),
  },
  chartBottomSection: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  realTimeContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    fontSize: 15,
    border: "1px solid #fff",
  },
  realTimeLabel: {
    fontWeight: 600,
    marginRight: theme.spacing(2),
  },
  realtimeValue: {},
  smallBoxes: {},
  smallBox: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: 9,
    border: "1px solid #fff",
    textAlign: "center",
  },
  smallBoxLabel: {
    fontWeight: 600,
  },
}));
