import { FC } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

interface Props {
  label: string;
  link: string;
  noMargin?: boolean;
  colorScheme?: "blue" | "white";
}

const ExternalLink: FC<Props> = ({
  label,
  link,
  noMargin = false,
  colorScheme = "blue",
}) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.linkWrapper} ${noMargin ? classes.noMargin : ""} ${
        colorScheme === "white" ? classes.white : ""
      }`}
    >
      <Link className={classes.link} to={link}>
        <Box className={classes.linkText}>{label}</Box>
        <Box className="link-icon">
          <KeyboardArrowRightIcon fontSize="small" />
        </Box>
      </Link>
    </Box>
  );
};

export default ExternalLink;
