export const STORY_LINES = [
  {
    id: 0,
    title: "Who we are",
    text: "Established in 2018, BITA has carved a niche in the Fintech landscape. Merging our expertise in technology and finance, we've grown as a leading provider of investment customization technology and indexing solutions. Today, our network encompasses Asset & Wealth managers, ETF Sponsors, Stock Exchanges, Trading Platforms, and other financial institutions across diverse markets.",
  },
  {
    id: 1,
    title: "What we do",
    text: "We offer infrastructure and managed services across 3 main business areas: Investment Technology, Indexing, and Research-driven Data. Our index development and calculation systems allow us to provide fully-customizable, fast, index implementations. Our investment technology and infrastructure allows clients to customize, backtest, and analyze exposures in minutes. Our Thematics and ESG data is precise and offers a granularity without precedents.",
  },
  {
    id: 2,
    title: "Why it matters",
    text: "Technology is enabling a completely new array of investment approaches and implementation options. From self-directed solutions to uber-customization of investment strategies, both retail and institutional investors are demanding cost-efficient, flexible investment solutions that are tailored to their specific objectives. But none of this can be achieved without modern and scalable infrastructure. This is where BITA comes in.",
  },
];

export const STATS = [
  {
    id: 0,
    label: "Employees",
    value: "60+",
  },
  {
    id: 1,
    label: "Offices",
    value: "4",
  },
  {
    id: 2,
    label: "Portfolios Created",
    value: "6000+",
  },
  {
    id: 3,
    label: "Index Values calculated per year",
    value: "2 Billion+",
  },
];
