import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  topSection: {
    padding: "0.75rem 1.5rem",
  },
  bottomSection: {},
  socials: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
  },
  socialIcon: {
    padding: theme.spacing(3),

    [theme.breakpoints.down("md")]: {
      padding: 0,
    },

    "& img": {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      maxWidth: theme.spacing(3.5),
    },
  },
  photoWrapper: {
    position: "relative",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),

    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      marginBottom: -1,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  photoMask: {
    position: "absolute",
    inset: 0,
    mixBlendMode: "color",
    backgroundColor: "#D9D9D9",
  },
  photoImg: {
    "& img": {
      width: "100%",
      height: "100%",
      margin: "auto",
      maxWidth: 338,
      maxHeight: 338,

      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "auto",
      },
    },
  },
  main: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.primary.main,
    padding: "1rem 0",
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down("md")]: {},
  },
  title: {
    fontSize: 15,
    [theme.breakpoints.down("md")]: {},
  },
  shortTitle: {
    fontSize: 30,
    fontWeight: 600,
    width: "100%",
    padding: "0.5rem",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
  description: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(3),
    fontSize: 14,
    fontWeight: 500,
    color: "#fff",
    background: "#383838CC",

    [theme.breakpoints.down("md")]: {
      position: "relative",
      fontSize: 16,
      textAlign: "center",
      marginTop: theme.spacing(1.5),
    },
  },
}));
