import React from "react";
import { Box, CircularProgress } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

interface Props {
  dataSeries: any;
  loading?: boolean;
  chartOptions?: any;
  size?: "sm" | "md" | "lg";
}

const TICK_PIXEL_INTERVAL = 120;

const SIZE_MAP = {
  sm: 4,
  md: 12,
  lg: 20,
};

const Chart: React.FC<Props> = ({
  dataSeries,
  loading,
  chartOptions = {},
  size = "md",
}) => {
  const startY = React.useMemo(() => {
    const data = dataSeries[0].data;
    const minValue = Math.min(...data.map((item: any) => item[1]));
    return Math.floor(minValue / 100) * 100;
  }, [dataSeries]);

  const options = {
    title: {
      text: "",
    },
    chart: {
      zoomType: "x",
      ...chartOptions.chart,
    },
    xAxis: {
      type: "datetime",
      labels: {
        formatter: function () {
          return moment.utc((this as any).value).format("YYYY/MM/DD");
        },
      },
      ...chartOptions.xAxis,
      tickPixelInterval: TICK_PIXEL_INTERVAL,
    },
    yAxis: {
      title: {
        text: "",
      },
      min: startY,
      startOnTick: false,
      tickmarkOffset: startY,
      tickPositioner: function () {
        return [
          startY,
          ...(this as any).tickPositions.filter(
            (position: number) => position > startY
          ),
        ];
      },
      ...chartOptions.yAxis,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        return `Date:&nbsp;&nbsp;<b>${moment
          .utc((this as any).x)
          .format("YYYY/MM/DD")}</b><br />Value:&nbsp;<b>${(this as any).y
          }</b>`;
      },
    },
    credits: {
      enabled: false,
    },
    series: dataSeries,
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      {loading ? (
        // While data is loading, show a loading spinner with a fixed height.
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: `${SIZE_MAP[size]}rem`,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        // If data is available, render the HighchartsReact component.
        <HighchartsReact highcharts={Highcharts} options={options} loading={loading} />
      )}
    </Box>
  );
};

export default Chart;
