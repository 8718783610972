import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderTop: "1px solid #fff",
    padding: "0 1.5rem 2rem",

    [theme.breakpoints.down("md")]: {},
    "&:last-type": {
      borderBottom: "1px solid #fff",
    },
  },
  head: {
    display: "flex",
    alignItems: "center",
    paddingTop: "1.5rem",
    paddingBottom: "1rem",
  },
  icon: {
    marginRight: "1.25rem",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  title: { fontSize: 25, lineHeight: 1.2, fontWeight: 600 },
  content: { fontSize: 17, lineHeight: 1.2 },
}));
