import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  labelContainer: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  label: {
    "&.MuiLink-root": {
      paddingLeft: 1,
      textDecoration: "none",
      fontWeight: 600,
      lineHeight: 1,
    },
  },
  linkContainer: {
    width: "100%",
    textAlign: "center",
    display: "none",

    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}));
