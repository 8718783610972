import { FC, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IMenuItem } from "../../../types/ui";
import MobileDropdown from "../../MobileDropdown";
import { useStyles } from "./styles";

interface Props {
  items: IMenuItem[];
}

const FooterMobileMenu: FC<Props> = ({ items }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [selected, setSelected] = useState<string>();

  const handleCloseItem = () => setSelected(undefined);

  const handleClickItem = (href?: string) => {
    handleCloseItem();

    if (!href) return;

    if (href.includes("://")) {
      window.open(href, "_blank");
    } else {
      navigate(href);
    }
  };

  return (
    <Box className={classes.root}>
      <Box>
        {items.map((item) => (
          <MobileDropdown
            {...item}
            key={item.id}
            open={item.id === selected}
            onOpen={setSelected}
            onClose={handleCloseItem}
            onClickItem={handleClickItem}
          />
        ))}
      </Box>
    </Box>
  );
};
export default FooterMobileMenu;
