import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "1rem",
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "center",
  },
  lg: {
    backgroundColor: "#485C6F",
    backgroundImage: "none !important",
    paddingLeft: "3rem",
    paddingRight: "2.5rem",
    width: "50%",
    marginLeft: "auto",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  md: {},
  label: {
    fontSize: 23,
    fontWeight: 700,
    color: "#fff",
  },
  description: {
    fontSize: 14,
    padding: "1rem 0 1.5rem",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
