import PdfDownloadCell from "../../components/PdfDownloadCell";
import { Column } from "../../types/ui";

export const PoliciesTableColumns: Column[] = [
  {
    key: "name",
    label: "POLICY NAME",
    sortable: false,
  },
  {
    key: "download",
    label: "DOWNLOAD POLICY",
    shortLabel: "DOWNLOAD",
    renderCell: (data) => (
      <PdfDownloadCell label="PDF File" href={data.download} />
    ),
  },
];

export const PoliciesTableData = [
  {
    name: "BITA Complaints Policy",
    download: "/pdfs/BITA_Complaints_Policy.pdf",
  },
  {
    name: "BITA Conflicts of Interest Policy",
    download: "/pdfs/BITA_Conflicts_of_Interest_Policy.pdf",
  },
  {
    name: "BITA Index Correction and Recalculation Policy",
    download: "/pdfs/BITA_Index_Correction_and_Recalculation_Policy.pdf",
  },
  {
    name: "BITA Index Methodology Policy",
    download: "/pdfs/BITA_Index_Methodology_Policy.pdf",
  },
  {
    name: "BITA Index Termination and Business Continuity Policy",
    download: "/pdfs/BITA_Index_Termination_and_Business_Continuity_Policy.pdf",
  },
  {
    name: "BITA Input Data Policy",
    download: "/pdfs/BITA_Input_Data_Policy.pdf",
  },
  {
    name: "BITA Internal Control Framework",
    download: "/pdfs/BITA_Internal_Control_Framework.pdf",
  },
  {
    name: "BITA Outsourcing Policy",
    download: "/pdfs/BITA_Outsourcing_Policy.pdf",
  },
  {
    name: "BITA Record Keeping Audit Trail Policy",
    download: "/pdfs/BITA_Record_Keeping_Audit_Trail_Policy.pdf",
  },
  {
    name: "BITA Register of Conflicts of Interest",
    download: "/pdfs/COI_Register_2019_05_30.xlsx",
  },
  {
    name: "BITA Whistleblowing Policy",
    download: "/pdfs/BITA_Whistleblowing_Policy.pdf",
  },
];
