import { FC } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { Box, Link } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  label: string;
  href: string;
}

const PdfDownloadCell: FC<Props> = ({ label, href }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.labelContainer}>
        <FilePresentOutlinedIcon fontSize="small" color="primary" />
        <Link className={classes.label} target="_blank" href={href}>
          {label}
        </Link>
      </Box>
      <Box className={classes.linkContainer}>
        <Link target="_blank" href={href}>
          <DownloadIcon htmlColor="#1684FF" />
        </Link>
      </Box>
    </>
  );
};

export default PdfDownloadCell;
