import { FC } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const ProductsLayout: FC<{}> = () => {
  return (
    <Box sx={{ bgcolor: "#1b1b1b", color: "#fff" }}>
      <Outlet />
    </Box>
  );
};

export default ProductsLayout;
