import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  mainContainer: {
    textAlign: "center",
  },
  main: {
    display: "inline-block",
    padding: "5rem 4rem",
    margin: "0 auto",
    textAlign: "left",
  },
  text: {
    fontSize: "1.25rem",
    lineHeight: 1.2,
    textAlign: "center",
  },
  section: {
    padding: "1.25rem 0",
    color: "#4B5158",

    "& a": {
      color: "#1684FF",
      fontWeight: 600,
      textDecoration: "underline",
    },
  },
  sectionHeading: {
    fontSize: "1.5rem",
    lineHeight: 1.2,
    fontWeight: 600,
    textAlign: "center",
  },
}));
