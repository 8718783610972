import { FC } from "react";
import { Box } from "@mui/material";
import Img0 from "../../assets/images/home/news/0.png";
import Img1 from "../../assets/images/home/news/1.png";
import Img10 from "../../assets/images/home/news/10.png";
import Img2 from "../../assets/images/home/news/2.png";
import Img3 from "../../assets/images/home/news/3.png";
import Img4 from "../../assets/images/home/news/4.png";
import Img5 from "../../assets/images/home/news/5.png";
import Img6 from "../../assets/images/home/news/6.png";
import Img7 from "../../assets/images/home/news/7.png";
import Img8 from "../../assets/images/home/news/8.png";
import Img9 from "../../assets/images/home/news/9.png";
import Carousel from "../Carousel";
import HomeCard from "../HomeCard";

const ITEMS = [
  {
    id: "202304-2",
    type: "NEW BLOG",
    date: "April 2023",
    title: "Decoding the EU Taxonomy: A Primer for Institutional Investors",
    link: "https://bita.ghost.io/decoding-the-eu-taxonomy-a-primer-for-institutional-investors-background/",
    image: Img10,
  },
  {
    id: "202304",
    type: "ANNOUNCEMENT",
    date: "April 2023",
    title: "BITA Indexes Power Plus500's New ESG Tranding Suite",
    link: "https://bita.ghost.io/bita-indexes-power-plus500s-new-esg-trading-suite/",
    image: Img9,
  },
  {
    id: "202303-2",
    type: "ANNOUNCEMENT",
    date: "March 2023",
    title:
      "BITA Supports the Future Generation of Finance Professionals at Rutgers University",
    link: "https://bita.ghost.io/bita-supports-the-future-generation-of-finance-professionals-at-rutgers-university-2/",
    image: Img8,
  },
  {
    id: "202303",
    type: "NEW BLOG",
    date: "March 2023",
    title:
      "The Lithium Industry: A Beneficiary of the Electric Vehicle Boom and the Energy Transition",
    link: "https://bita.ghost.io/the-lithium-industry-a-beneficiary-of-the-electric-vehicle-boom-and-the-energy-transition/?utm_source=linkedin&utm_medium=social&utm_campaign=lithium_post",
    image: Img0,
  },
  {
    id: "202211-2",
    type: "NEW PRODUCT",
    date: "November 2022",
    title:
      "Track the environmental footprint of your investment portfolios with new environmental impact reports.",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:7008820053521395712",
    image: Img1,
  },
  {
    id: "202211",
    type: "NEW PRODUCT",
    date: "November 2022",
    title:
      "The World’s Most Granular Thematic Database just got bigger with 15 new thematics!",
    link: "/products/thematic-data",
    image: Img2,
  },
  {
    id: "202210",
    type: "ETF ANNOUNCEMENT",
    date: "October 2022",
    title:
      "Our partners from Global X ETFs AU launch their Global X Green Metal Miners ETF! ",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:6991062073241677824",
    image: Img3,
  },
  {
    id: "202209",
    type: "PARTNERSHIP",
    date: "September 2022",
    title:
      "BITA iCLIMA launch a range of environmental-impact indexing, data and analytics solutions",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:6977929381268570113",
    image: Img4,
  },
  {
    id: "202208-2",
    type: "ANNOUCEMENT",
    date: "August 2022",
    title: "2ndVote Advisers to leverage BITA’s direct-indexing platform",
    link: "https://www.einnews.com/pr_news/588532925/2ndvote-to-leverage-bita-s-direct-indexing-platform-to-develop-innovative-investment-products",
    image: Img5,
  },
  {
    id: "202208",
    type: "NEW HIRE",
    date: "August 2022",
    title: "Meet Nicolas Ameline, our new MD of Sales and Partnerships.",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:6960572552431714304",
    image: Img6,
  },
  {
    id: "202206",
    type: "NEW HIRE",
    date: "June 2022",
    title: "Meet our new Chief Product Officer, Leslie Nicholson.",
    link: "https://www.linkedin.com/feed/update/urn:li:activity:6947179316250066944",
    image: Img7,
  },
];

const IndexLabCardCarousel: FC<{}> = () => {
  return (
    <Carousel>
      {ITEMS.map(({ id, type, date, title, link, image }) => (
        <Box key={id}>
          <HomeCard
            type={type}
            title={title}
            date={date}
            bgImage={image}
            link={link}
          />
        </Box>
      ))}
    </Carousel>
  );
};

export default IndexLabCardCarousel;
