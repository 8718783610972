import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  linkWrapper: {
    marginTop: "0.5rem",
    marginRight: "2rem",
  },
  noMargin: {
    marginTop: 0,
    marginRight: 0,
  },
  link: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.75rem 0.75rem",
    marginLeft: "-0.75rem",
    color: "#fff",
    fontWeight: 600,
    textDecoration: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "transparent",
    borderRadius: 8,
    overflow: "hidden",

    "&:hover": {
      borderColor: "#fff",
      color: "#fff",

      "& svg": {
        backgroundColor: "#fff",
        fill: "#235CFF",
      },
    },
    "&:active": {
      borderColor: "#235CFF",
      color: "#fff",
      backgroundColor: "#235CFF",

      "& svg": {
        background: "#235CFF",
        fill: "#fff",
      },
    },

    "& .link-icon": {
      width: 20,
      height: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#235CFF",
      marginLeft: "0.75rem",
      borderRadius: 6,
      overflow: "hidden",
    },
  },
  white: {
    "& svg": {
      backgroundColor: "#fff",
      fill: "#235CFF",
    },
  },
  linkText: {
    lineHeight: 1,
  },
}));
