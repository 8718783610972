import { ChangeEvent, FC, useMemo, useState } from "react";
import { 
  Box, 
  Grid, 
  Pagination, 
  // SelectChangeEvent 
} from "@mui/material";
import { useIsMobileView } from "../../hooks/useWindowSize";
import ClientCard from "../ClientCard";
import SearchInput from "../SearchInput";
// import Select from "../Select";
import { CLIENTS_LIST } from "./data";
import { useStyles } from "./styles";

const ClientsList: FC = () => {
  const classes = useStyles();

  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  // const [type, setType] = useState<string>("");

  const inMobile = useIsMobileView();

  // const handleChangeType = (event: SelectChangeEvent<any>) => {
  //   setType(event.target.value);
  // };

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    console.log('event ', event)
    console.log('event.target.value ', event.target.value)
    setQuery(event.target.value);
  };

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const visibleItems = useMemo(() => {
    return CLIENTS_LIST.filter(
      (item) => 
        query.length < 3 ||
        item.text.toLowerCase().includes(query.toLowerCase()) ||
        item.clientName.toLowerCase().includes(query.toLowerCase())
    );
  }, [query]);

  const pageSize = useMemo(() => (inMobile ? 3 : 9), [inMobile]);
  const pageStart = (page - 1) * pageSize;
  const pageEnd = Math.min(page * pageSize, visibleItems.length);
  const itemsInPage = useMemo(() => {
    return visibleItems.slice(pageStart, pageEnd);
  }, [pageEnd, pageStart, visibleItems]);
  const paginationCount = Math.ceil(visibleItems.length / pageSize);

  return (
    <Box className={classes.root}>
      {/* TODO @adasilva hide this for now, in another task I will add the functionality (right now is not working) */}
       <Box className={classes.selectsContainer}>
        {/*<Select
          label="Type of Client"
          value={type}
          onChange={handleChangeType}
          options={[]}
          width={inMobile ? "100%" : 180}
          displayAll
        />*/}
        <div></div> {/* TODO @adasilva Remove after, it's just to align the finder to the right */}
        
        <SearchInput
          label=""
          placeholder="Search by..."
          onChange={handleChangeQuery}
          fullWidth={inMobile}
        />
      </Box> 
      <Box>
        <Grid className={classes.listContainer} container spacing={0}>
          {itemsInPage.map((item) => (
            <Grid item xs={12} sm={4}>
              <ClientCard {...item} />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box className={classes.listFooter}>
              <Box className={classes.footerInfo}>
                Displaying {pageStart + 1} -&nbsp;
                {pageEnd}&nbsp; of&nbsp;
                {visibleItems.length} results
              </Box>
              <Pagination
                count={paginationCount}
                page={page}
                onChange={handleChangePage}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ClientsList;
