import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1rem 0rem 1rem",
  },
  mobileContainer: {
    "& .bita-carousel": {
      "& .slick-track": {
        display: "flex",
      },
      "& .slick-slide": {
        height: "inherit",

        "& > div": {
          height: "100%",
        },
      },
      "& .slick-prev, & .slick-next": {
        width: "1.5rem",
        height: "1.5rem",
        fill: "#017F63",
        color: "#017F63",
        zIndex: 1,

        "&:active, &:hover": {
          fill: "#017F63",
          color: "#017F63",
          "& svg": {
            fill: "#017F63",
            stroke: "#017F63",
          },
        },

        "& svg": {
          fill: "#017F63",
          stroke: "#017F63",
        },
      },
      "& .slick-prev": {
        left: 0,
      },
      "& .slick-next": {
        right: 0,
      },
    },
  },
  mobileCard: {
    height: "100%",
    padding: "0 4rem",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  mobileCardIcon: {},
  mobileCardTitle: {
    fontSize: 22,
    fontWeight: 700,
    padding: "1rem 0 0.75rem",
  },
  mobileCardContent: {
    fontSize: 15,
  },
}));
