import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  selectsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(6),

    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  listContainer: {},
  listFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  footerInfo: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  pagination: {},
}));
