import { FC, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ImgPolygon from "../../assets/images/home/polygon.svg";
import ExternalLink from "../ExternalLink";
import { useStyles } from "./styles";

const leftVariants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.6 },
  },
  hidden: {
    x: "-40vw",
    opacity: 0,
    transition: { ease: "easeOut", duration: 0.6 },
  },
};

const rightVariants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.6 },
  },
  hidden: {
    x: "40vw",
    opacity: 0,
    transition: { ease: "easeOut", duration: 0.6 },
  },
};

interface Props {
  subTitle: string;
  title: string;
  text: string[];
  image: any;
  link: string;
  direction?: "reverse" | "standard";
}

const HomeSection: FC<Props> = ({
  subTitle,
  title,
  text,
  image,
  link,
  direction = "standard",
}) => {
  const classes = useStyles();
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.04 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <Grid
      container
      className={`${classes.section} ${direction}`}
      spacing={4}
      flexWrap="wrap-reverse"
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        className={`${classes.sectionContent} ${direction}`}
      >
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={direction === "standard" ? leftVariants : rightVariants}
          className="home-section"
          style={{ backgroundImage: `url(${ImgPolygon})` }}
        >
          <Box className={classes.sectionContentInner}>
            <Box className={classes.sectionSubTitle}>{subTitle}</Box>
            <Box className={classes.divider}></Box>
            <Box className={classes.sectionTitle}>{title}</Box>
            <Box className={classes.sectionText}>
              {text.map((str, idx) => (
                <Box key={str + idx} pb={2}>
                  {str}
                </Box>
              ))}
            </Box>
            <ExternalLink label="Learn More" link={link} />
          </Box>
        </motion.div>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.sectionImage}>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={direction === "standard" ? rightVariants : leftVariants}
          className="home-section"
        >
          <img src={image} alt={title} />
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default HomeSection;
