import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "1.25rem 1.5rem",
    margin: "0 1.5rem",
    color: "#fff",
    height: theme.spacing(35),
    display: "flex",
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "black",
  },
  header: {
    fontSize: 11,
    fontWeight: 500,
    borderBottom: "1px solid #fff",
    maxWidth: "fit-content",
  },
  body: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    fontWeight: 600,
  },
  footer: {
    fontWeight: 600,
  },
  download: {
    cursor: "pointer",
  },
}));
