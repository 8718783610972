import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: "2.5rem 0",

    "&.reverse": {
      flexDirection: "row-reverse",
    },
  },
  sectionContent: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,

    "& .home-section": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      padding: "2rem",

      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: "5rem",
        borderBottom: "1px solid #235CFF",
      },
    },

    "&.standard .home-section": {
      paddingRight: "3rem",

      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  sectionContentInner: {},
  sectionSubTitle: {
    color: "#96AAF0",
    fontSize: 15,
    fontWeight: 700,
  },
  sectionTitle: {
    fontSize: 30,
    fontWeight: 600,
    paddingBottom: "1.5rem",
  },
  sectionText: {
    fontSize: "1rem",
  },
  sectionImage: {
    "& img": {
      margin: "0 auto",
    },
  },
  divider: {
    width: "4rem",
    height: "2px",
    backgroundColor: "#698BEC",
    margin: "0.5rem 0",
  },
}));
