import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {
    backgroundColor: "#1b1b1b",
  },
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  text: {
    maxWidth: theme.spacing(90),
    fontSize: 20,
    lineHeight: 1.2,
  },
  main: {
    padding: "3rem 0rem",
  },
  mainDivider: {
    width: "100%",
    height: 2,
    background: "linear-gradient(360deg, #4995FF 0%, #235CFF 100%)",
    margin: "3rem 0",
  },
  construction: {
    position: "relative",
    margin: "4rem 0",
    background: "#25246D",
  },
  constructionImgWrapper: {
    paddingTop: theme.spacing(4),
    marginLeft: theme.spacing(-8),
    marginBottom: theme.spacing(-4),

    [theme.breakpoints.down("md")]: {
      opacity: 0.4,
      position: "absolute",
      width: "calc(100% + 5rem)",
      top: "50%",
      left: theme.spacing(-10),
      right: 0,
      transform: "translateY(-50%)",
      paddingTop: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
  },
  constructionContent: {
    position: "relative",
  },
  constructionTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: 30,
    fontWeight: 600,
    color: "#fff",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  list: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: "2rem",

    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(-1),
    },
  },
  data: {
    position: "relative",
    background: "#25246D",
  },
  dataBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    clipPath: `polygon(
      0 50%,10% 20%,90% 20%,100% 0,100% 100%,0 100%
     )`,
    backgroundColor: "#25246D",
  },
  dataContent: {
    position: "relative",
    padding: "4rem 0",
  },
  universe: {
    position: "relative",
  },
  universeBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  universeContentWrapper: {
    padding: "7.5rem 0",

    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  universeContent: {
    position: "relative",
    height: "100%",
    maxWidth: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  universeText: {
    ...pageStyles(theme).mainSubHeading,
    fontWeight: 400,
  },
  boxWithUnderline: {
    marginTop: 6,
    marginLeft: "1.5rem",

    [theme.breakpoints.down("md")]: {
      marginLeft: "1rem",
    },
  },
  underline: {
    width: "6rem",
    height: 1,
    backgroundColor: "#fff",
    marginTop: 6,
  },
  analysisContainer: {
    position: "relative",
    paddingBottom: "2.5rem",
    overflow: "hidden",
  },
  analysisImgWrapper: {
    marginLeft: theme.spacing(-2),

    [theme.breakpoints.down("md")]: {
      opacity: 0.2,
      position: "absolute",
      width: "calc(100% + 5rem)",
      top: "50%",
      left: 0,
      right: theme.spacing(-10),
      transform: "translateY(-50%)",
      paddingTop: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
  },
  mainText: {
    ...pageStyles(theme).mainText,

    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
}));
