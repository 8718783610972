import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: "1.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "#1B1B1B",
    borderRadius: "2px",
  },
  title: {
    fontSize: 30,
    lineHeight: 1.2,
    fontWeight: 600,
  },
  description: {
    fontSize: 16,
    lineHeight: 1.2,
    paddingTop: "0.5rem",
  },
  listWrapper: {
    paddingTop: "2rem",
    fontWeight: 600,
  },
  list: {
    listStyle: "disc",
    paddingLeft: "1.5rem",
  },
  learnMore: {
    paddingTop: "1.5rem",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
}));
