import { IndexLab } from "../types/indexes";

export const getDownloadLinksFromIndexLab = (item: IndexLab) => {
  return [
    { label: "Factsheet", url: item.factsheet_url },
    { label: "Price Series", url: item.prices_series_url },
    { label: "PIM", url: item.pim_url },
    { label: "Brochure File", url: item.index_brochure_file },
  ].filter(({ url }) => !!url);
};
