
import Img1 from "../../assets/images/products/thematic-data/data1.png";
import Img2 from "../../assets/images/products/thematic-data/data2.png";
import Img3 from "../../assets/images/products/thematic-data/data3.png";
import Img4 from "../../assets/images/products/thematic-data/data4.png";
import Img5 from "../../assets/images/products/thematic-data/data5.png";
import Img6 from "../../assets/images/products/thematic-data/data6.png";
import Img7 from "../../assets/images/products/thematic-data/data7.png";
import Carousel from "../Carousel";
import ThematicDataCarouselCard from "./Card";

const ThematicDataCarousel = () => {
  return (
    <Carousel options={{ infinite: true }}>
      <ThematicDataCarouselCard
        image={Img1}
        title="Emerging trends"
        text="Stay up-to-date with the most exciting trends and developments affecting the global marketplace."
        reverseTexts={[
          "African Consumer",
          "Emerging Market Consumer",
          "Growing Millionaire Class",
          "Islamic Shariah",
          "LGBT Empowerment",
          "China Internet",
          "Sharing Economy",
          "Vegan and Organics",
          "Women Empowerment",
          "Remote Economy",
          "Mobility Solutions",
        ]}
      />
      <ThematicDataCarouselCard
        image={Img2}
        title="Future Technologies"
        text="Discover the key technology stacks driving innovation across industries. Stay on top of the disruption curve."
        reverseTexts={[
          "Robotics and AI",
          "3D Printing",
          "5G",
          "Big Data",
          "Blockchain and DLT",
          "Cloud Computing",
          "Virtual Reality and Augmented Reality",
          "Messenger RNA",
          "Genome Editing",
          "Photonics and Laser Technology",
          "Nanotechnology",
        ]}
      />
      <ThematicDataCarouselCard
        image={Img3}
        title="Structural Shifts"
        text="Keep track of the social and macroeconomic developments influencing the way we live."
        reverseTexts={[
          "Aging Population",
          "Rapid Urbanization",
          "Resource Scarcity & Overpopulation",
        ]}
      />
      <ThematicDataCarouselCard
        image={Img4}
        title="Future of Industries"
        text="Identify the companies driving disruption and building the future of key industries and sectors."
        reverseTexts={[
          "Communication Tech",
          "Energy Tech",
          "Financial Tech",
          "Health Tech",
          "Industrial Tech",
          "Infrastructure Tech",
          "Materials Tech",
          "Transportation Tech",
          "Clean Tech",
        ]}
      />

      <ThematicDataCarouselCard
        image={Img5}
        title="Controversial"
        text="Times of conflict and controversy are increasingly popular as investors search for new and innovative ways to allocate their capital. Leverage our research and tech to make the right decisions."
        reverseTexts={[
          "Defense",
          "Firearms",
          "Controversial Weapons",
          "Fossil Fuels",
          "Thermal Coal",
          "Alcohol",
          "Tobacco",
          "Adult Entertainment",
          "Gambling",
          "Genetically Modified Organisms",
          "Nuclear",
          "Pork",
          "Recreational Drugs",
          "Stem Cells",
        ]}
      />

      <ThematicDataCarouselCard
        image={Img6}
        title="Positive Impact"
        text="Sustainability is more than a buzzword – it’s a critical factor in the future of our planet and our economy. Investors who understand this have a chance to change the world and make a difference."
        reverseTexts={[
          "Climate Impact",
          "Sustainability of Resources Impact",
          "Quality of Life Impact",
          "Equality and Inclusion Impact",
        ]}
      />

      <ThematicDataCarouselCard
        image={Img7}
        title="Emerging Industries"
        text="We are always keeping an eye out for new trends in the global marketplace. Stay up-to-date with what is happening around the world for new and exciting ways to generate strong returns."
        reverseTexts={[
          "Aeronautical", 
          "Medical Biotechnology",
          "Cannabis",
          "Electric Vehicles",
          "Green Energy",
          "Cybersecurity",
          "Lithium and Battery Metals",
          "Copper Miners",
          "Rare Earth Elements",
          "Social Media",
          "Space",
          "Videogames and e-Sports",
          "Metaverse",
          "Green Energy Minerals",
          "NFTs",
          "Psychedelics",
          "Human Augmentation",
          "Uranium",
          "Airlines and Airports",
          "Agricultural Biotechnology",
          "And More...",
        ]}
      />
    </Carousel>
  );
};

export default ThematicDataCarousel;
