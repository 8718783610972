import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  rootCenter: {
    margin: "0 auto",
  },
  main: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
  },
  mainDivider: {
    width: "100%",
    height: 2,
    background: "linear-gradient(360deg, #4995FF 0%, #235CFF 100%)",
    margin: "3rem 0",
  },
  mainHeading: {
    ...pageStyles(theme).mainHeading,
    fontSize: 50,
    color: theme.palette.primary.main,
  },
  mainText: {
    fontSize: 20,
    paddingBottom: theme.spacing(6),
  },
  text: {
    fontSize: 20,
    lineHeight: 1.2,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  section: {
    padding: "1.25rem 0",
    color: "#4B5158",

    "& a": {
      color: "#1684FF",
      fontWeight: 600,
      textDecoration: "underline",
    },
  },
  sectionHeading: {
    fontSize: 30,
    lineHeight: 1.2,
    fontWeight: 600,
  },
  sectionSubHeading: {
    fontSize: 25,
    lineHeight: 1.2,
    fontWeight: 600,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}));
