import { FC } from "react";
import { Box } from "@mui/material";
import { useIsMobileView } from "../../hooks/useWindowSize";
import Carousel from "../Carousel";
import { CLIENT_LIST, CLIENT_LIST_DESKTOP } from "./data";
import { useStyles } from "./styles";

const HomeClientsList: FC<{}> = () => {
  const classes = useStyles();
  const inMobile = useIsMobileView();

  if (inMobile) {
    return (
      <Box className={classes.mobileContainer}>
        <Carousel
          options={{
            infinite: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 2,
                  initialSlide: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
            ],
          }}
        >
          {CLIENT_LIST.map(({ id, label, image }) => (
            <Box key={id} className={classes.item}>
              <img className={classes.image} src={image} alt={label} />
            </Box>
          ))}
        </Carousel>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Carousel
        options={{
          slidesToShow: 1,
          infinite: true,
          responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }}
      >
        {CLIENT_LIST_DESKTOP.map((sublist, idx) => (
          <Box key={idx}>
            {sublist.map((row, rowIdx) => (
              <Box key={rowIdx} className={classes.row} px={2}>
                {row.map(({ id, image, label }) => (
                  <Box key={id} className={classes.item}>
                    <img
                      key={id}
                      className={classes.image}
                      src={image}
                      alt={label}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default HomeClientsList;
