import { Announcement } from "../../types/announcements";
import api from "../api";

export const getAnnouncements = async (): Promise<{ data: Announcement[] }> => {
  const { data } = await api.get("/v2/announcement");
  return data;
};

export const getAnnouncementCategories = async (): Promise<any> => {
  const { data } = await api.get("/v2/announcement/categories");
  return data;
};

export const getAnnouncementFile = async (fileId: number): Promise<any> => {
  const { data } = await api.get(
    `/v2/announcement/file?announcement_id=${fileId}`,
    {
      responseType: "blob", // "responseType" should be set to "blob" to indicate this is file download
    }
  );
  return data;
};
