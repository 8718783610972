import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "7.5rem",
    padding: "1.25rem",
    color: "#fff",
    cursor: "pointer",
  },
  background: {
    position: "absolute",
    top: 0,
    bottom: "-4rem",
    left: "calc(100% - 6rem)",
    right: "-6rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  title: {
    fontSize: "0.75rem",
    borderBottom: "1px solid #fff",
    paddingBottom: 6,
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 1,
  },
  type: {
    fontSize: "1rem",
    lineHeight: 1.2,
    fontWeight: 600,
    textTransform: "capitalize",
  },
}));
