import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  headerMask: {
    position: "absolute",
    width: "100%",
    height: 26,
    left: 0,
    bottom: 12,
  },
  headerMask2: {
    position: "absolute",
    width: "100%",
    height: 12,
    left: 0,
    bottom: 0,
  },
  responsiveHeading: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "5vw",
    },
  },
}));
