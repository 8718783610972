import { FC } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { useStyles } from "./styles";

interface Props {
  color: string;
}

const ContactUsButton: FC<Props> = ({ color }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onClick = () => navigate("/contact-us");

  return (
    <Box className={classes.button}>
      <Button
        className={classes.contactButton}
        onClick={onClick}
        label="Contact Us"
        sx={{
          color,
        }}
      />
    </Box>
  );
};

export default ContactUsButton;
