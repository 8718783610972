import { FC } from "react";
import { Box, Link } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  type: string;
  title: string;
  date: string;
  bgImage: string;
  link: string;
}

const HomeCard: FC<Props> = ({ type, title, date, link, bgImage }) => {
  const classes = useStyles();

  return (
    <Link href={link} target="_blank">
      <Box
        className={classes.root}
        sx={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <Box className={classes.type}>{type}</Box>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.date}>{date}</Box>
      </Box>
    </Link>
  );
};

export default HomeCard;
