import { FC, useState } from "react";
import { Box } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  id: number;
  name: string;
  position: string;
  shortPosition: string;
  image: string;
  description: string;
  inMobile: boolean;
}

const TeamMember: FC<Props> = ({
  name,
  position,
  shortPosition,
  image,
  description,
  inMobile,
}) => {
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box className={classes.root}>
      <Box
        className={classes.photoWrapper}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box className={classes.photoMask} />
        <Box className={classes.photoImg}>
          <img src={image} alt="Avatar" />
        </Box>
        {isHovered && !inMobile && (
          <Box className={classes.description}>{description}</Box>
        )}
      </Box>

      <Box className={classes.main}>
        <Box className={classes.name}>{name}</Box>
        <Box className={classes.title}>
          {inMobile ? shortPosition : position}
        </Box>
        {inMobile && <Box className={classes.description}>{description}</Box>}
      </Box>
    </Box>
  );
};

export default TeamMember;
