import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  text: {
    maxWidth: theme.spacing(90),
    fontSize: 20,
    lineHeight: 1.2,
  },
  main: {
    padding: "3rem 6rem",
  },
  mainHeading: {
    ...pageStyles(theme).mainHeading,
    color: theme.palette.primary.main,
  },
  tableContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  withBorderBottom: {
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  sectionHeading: {
    fontSize: 30,
    lineHeight: 1.2,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));
