import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SpeedIcon from "@mui/icons-material/Speed";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Container, Grid } from "@mui/material";
import CryptoFeeds from "../../../components/CryptoFeeds";
import CryptoOfferingCard from "../../../components/CryptoOfferingCard";
import CryptoOfferingCarousel from "../../../components/CryptoOfferingCarousel";
import CryptoOfferingData from "../../../components/CryptoOfferingData";
import PageHero from "../../../components/PageHero";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStore";
import {
  selectCryptocurrencies,
  fetchCryptocurrencies,
} from "../../../redux/slices/assets";
import { getAnalyzerToken } from "../../../redux/slices/auth";
import { useStyles } from "./styles";

const CARDS = [
  {
    id: 0,
    icon: <ViewDayIcon />,
    title: "Range of Strategies",
    content: "Size, sector, thematic, quantitative, ESG...",
  },
  {
    id: 1,
    icon: <WorkIcon />,
    title: "Broad Coverage",
    content: "Universe of more than 1000 tokens.",
  },
  {
    id: 2,
    icon: <SearchIcon />,
    title: "Data Expertise",
    content: "Cleansed, normalized, reference and market data.",
  },
  {
    id: 3,
    icon: <SpeedIcon />,
    title: "EU Benchmark Regulation",
    content:
      "As a BMR administrator, our indexes are fully compliant with BMR and IOSCO.",
  },
  {
    id: 4,
    icon: <ThermostatIcon />,
    title: "Institutional-grade fixings",
    content: "Customizable fixing timings and methodologies.",
  },
];

const CryptoOffering = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const cryptocurrencies = useAppSelector(selectCryptocurrencies);

  useEffect(() => {
    dispatch(fetchCryptocurrencies());
  }, [dispatch]);

  useEffect(() => {
    // Get analyzer api token, which is required in future api requests
    dispatch(
      getAnalyzerToken({
        username: process.env.REACT_APP_ANALYZER_API_USER ?? "",
        password: process.env.REACT_APP_ANALYZER_API_PWD ?? "",
      })
    );
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <PageHero
        colorScheme="gray"
        mainContentColorScheme="dark"
        title="Crypto Index and Data Offering"
        subTitle="Empower your cryptocurrency strategies."
      />

      <Container className={classes.main}>
        <Box>
          <Box className={classes.mainHeading}>Pioneering on Crypto</Box>
          <Box className={classes.mainText} mt={3}>
            One of the world's first crypto indexers, BITA offers the world's
            widest coverage of crypto indexes and strategies. From traditional
            size indexes to sectorial and thematic strategies, we help global
            institutions to launch unique, innovative investment products.
          </Box>
        </Box>

        <Grid className={classes.sectionContainer} container spacing={3}>
          {CARDS.map((card) => (
            <Grid key={card.id} item xs={12} sm={6}>
              <CryptoOfferingCard {...card} />
            </Grid>
          ))}
        </Grid>
        <Box className={classes.sectionBorder} />
      </Container>

      <Box>
        <CryptoOfferingData />
        <Container>
          <Box className={classes.sectionBorder} pt={0} />
        </Container>
      </Box>

      <Container className={classes.main}>
        <Box className={classes.mainHeading}>
          BITA Crypto Data · Consolidated Feeds
        </Box>

        <Box>
          <CryptoFeeds cryptocurrencies={cryptocurrencies} />
        </Box>
        <Box className={classes.sectionBorder} />
      </Container>

      <Container className={classes.main}>
        <Box>
          <Box className={classes.mainHeading}>BITA Cryptocurrency Indexes</Box>
          <Box>
            <CryptoOfferingCarousel />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CryptoOffering;
