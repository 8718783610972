import { FC } from "react";
import { Box } from "@mui/material";
import { News } from "../../types/news";
import Carousel from "../Carousel";
import CaseStudyCard from "../CaseStudyCard";

interface Props {
  data: News[];
}

const ClientsCaseStudies: FC<Props> = ({ data }) => {
  return (
    <Box pt={3}>
      <Carousel
        options={{
          slidesToShow: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        }}
      >
        {data.map((item) => (
          <CaseStudyCard key={item.id_m_web_news} data={item} />
        ))}
      </Carousel>
    </Box>
  );
};

export default ClientsCaseStudies;
