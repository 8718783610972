import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/useStore";
import { useIsMobileView } from "../../hooks/useWindowSize";
import { IndexTableRow } from "../../types/indexes";
import { Column } from "../../types/ui";
import LoadingSpinner from "../LoadingSpinner";
import SearchInput from "../SearchInput";
import Select from "../Select";
import Table from "../Table";

import { useStyles } from "./styles";

interface Props {
  columns: Column[];
  mobileColumnIds: string[];
  dataFilterFunc?: (row: IndexTableRow) => boolean;
  queryParams?: {
    assetClass: string | null;
    indexType: string | null;
  };
}

const IndexTable: FC<Props> = ({
  columns,
  mobileColumnIds,
  dataFilterFunc = () => true,
  queryParams,
}) => {
  const classes = useStyles();
  const {
    families,
    indexes,
    eod,
    indexList,
    assetClassOptions,
    indexTypeOptions,
  } = useAppSelector((state) => state.indexes);

  const navigate = useNavigate();

  const [assetClass, setAssetClass] = useState<string>("");
  const [indexFamily, setIndexFamily] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(-1);

  const inMobile = useIsMobileView();

  const refTableContainer = useRef<HTMLDivElement>(null);

  const handleChangeAssetClass = (event: SelectChangeEvent<any>) => {
    const newAssetClass = event.target.value;
    
    if (newAssetClass !== assetClass) {
      // Clear the indexFamily state when a different asset class is selected.
      setIndexFamily("");
    }
  
    setAssetClass(newAssetClass);
  
    // Update the URL with the new asset class.
    navigate(`/index?asset_class=${newAssetClass}`);
  };

  const handleChangeIndexFamily = (event: SelectChangeEvent<any>) => {
    setIndexFamily(event.target.value);
  };

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  // Whenever table page changes, this will scroll the page to the table top for better UX
  const scrollToTableTop = () => refTableContainer.current?.scrollIntoView();

  const acOptions = useMemo(() => {
    if (!families) return [];
    return Object.keys(families).map((k) => ({
      label: k,
      value: k,
    }));
  }, [families]);

  const indexFamilyOptions = useMemo(() => {
    if (!families || !assetClass || !families[assetClass]) return [];
    return Object.keys(families[assetClass]).map((k) => ({
      value: k,
      label: k,
    }));
  }, [assetClass, families]);

  const tableData: IndexTableRow[] = useMemo(() => {
    if (!indexes) return [];
    return indexes
      .filter((index) => {
        const listItem = indexList?.find(
          (item) => item.ticker === index.index_id
        );

        // Hide any items with non-active status
        if (listItem?.web_status !== "A") return false;

        // Filter items by asset-class and index-family selectors
        if (!assetClass) return true;

        if (!indexFamily) {
          const range = Object.keys(families[assetClass]).flatMap(
            (key) => families[assetClass][key]
          );

          return !!range.find((item) => item.symbol === index.index_id);
        }

        return !!families[assetClass][indexFamily]?.find(
          (item) => item.symbol === index.index_id
        );
      })
      .map((index) => {
        // Add "daily close" value to each item
        const eodItem = eod?.[index.index_id];
        const dailyClose = eodItem?.length
          ? eodItem[eodItem.length - 1]?.value?.toFixed(2)
          : null;

        const listItem = indexList?.find(
          (item) => item.ticker === index.index_id
        );

        return {
          ...index,
          name: index.name.trim(), // Sometimes "name" field includes leading whitespaces in it
          daily_close: dailyClose ? parseFloat(dailyClose) : null,
          files: listItem?.files ?? [], // Get files from list item
        };
      })
      .filter(dataFilterFunc);
  }, [
    assetClass,
    dataFilterFunc,
    eod,
    families,
    indexFamily,
    indexList,
    indexes,
  ]);


  const visibleColumns = useMemo(() => {
    // in mobile, there should be different columns than in desktop
    return inMobile
      ? columns
          .filter((c) => mobileColumnIds.includes(c.key))
          .map((col) =>
            col.key === "daily_close" ? { ...col, textAlign: "right" } : col
          )
      : columns;
  }, [columns, inMobile, mobileColumnIds]);

  const visibleKeys = useMemo(() => {
    return visibleColumns.map((c) => c.key);
  }, [visibleColumns]);

  // Filter table rows by "search" input value
  const filteredTableData = useMemo(() => {
    if (query?.length < 3) return tableData;
    return tableData.filter((item) => {
      return visibleKeys.some(
        (k) =>
          (item as any)[k] &&
          ((item as any)[k] as any)
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
      );
    });
  }, [query, tableData, visibleKeys]);

  useEffect(() => {
    // Reset table page to 0 when either asset class or index family changes
    // Required because total number of rows depends on asset class & index family selections
    setPage(0);
  }, [assetClass, indexFamily]);

  useEffect(() => {
    // Reset table page to 0 when search query gets updated
    if (query.length >= 3) {
      setPage(0);
    }
  }, [query]);

  useEffect(() => {
    if (queryParams?.assetClass) {
      // if there's query param for "asset class"
      // update state value with it
      const assetClass = assetClassOptions.find(
        (aco) => aco.id.toString() === queryParams.assetClass
      )?.name;
      if (assetClass) {
        setAssetClass(assetClass);
      }
    }
  }, [assetClassOptions, queryParams?.assetClass]);

  useEffect(() => {
    if (queryParams?.indexType) {
      // if there's query param for "index type"
      // update state value with it
      const indexType = indexTypeOptions.find(
        (ito) => ito.id.toString() === queryParams.indexType
      )?.name;
  
      if (indexType) {
        setIndexFamily(indexType);
      }
    } else {
      // If there's no query param for "index type" in the URL,
      // clear the indexFamily state.
      setIndexFamily("");
    }
  }, [assetClassOptions, indexTypeOptions, queryParams?.indexType]);

  return (
    <Box
      ref={refTableContainer}
      className={`${classes.tableContainer} ${classes.withBorderBottom}`}
    >
      <Box className={classes.selectorsRow}>
        <Box className={classes.searchContainer}>
          <SearchInput
            label=""
            placeholder="Search by Name or Ticker..."
            onChange={handleChangeQuery}
            fullWidth={inMobile}
          />
        </Box>
        <Box className={classes.selectorsContainer}>
          <Box className={classes.selectContainer}>
            <Select
              label="Asset Class"
              value={assetClass}
              onChange={handleChangeAssetClass}
              options={acOptions}
              width={inMobile ? "100%" : 180}
              displayAll
            />
          </Box>
          <Box className={classes.selectContainer}>
            <Select
              label="Index Family"
              value={indexFamily}
              onChange={handleChangeIndexFamily}
              options={indexFamilyOptions}
              width={inMobile ? "100%" : 180}
              disabled={!assetClass}
              displayAll
            />
          </Box>
        </Box>
      </Box>
      {indexes?.length ? (
        <Table
          columns={visibleColumns}
          data={filteredTableData}
          defaultOrderBy="name"
          onPageChange={scrollToTableTop}
          currentPage={page}
          setCurrentPage={setPage}
        />
      ) : (
        <LoadingSpinner />
      )}
    </Box>
  );
};

export default IndexTable;
