import { FC } from "react";
import { Box } from "@mui/material";
import { PageType } from "../../types/ui";
import ContactUsButton from "../ContactUsButton";
import { useStyles } from "./styles";

interface Props {
  type: PageType;
  color: string;
}

const TEXTS: { [key in PageType]: { title: string; body: string } } = {
  contact: {
    title: "Contact Us",
    body: "Contact us for more information and updates, as well as for any inquiries regarding access to our platform and services.",
  },
  license: {
    title: "License this Index",
    body: "In case you want to include this instrument in your product portfolio, please contact us, one of our Sales representatives will get back to you with more licensing details.",
  },
  product: {
    title: "Enhance your product development!",
    body: "Experience the world’s most powerful indexing construction platform! Contact our Sales team to discover our licensing pricing.",
  },
  license_index: {
    title: "License best-in-class indexes!",
    body: "Contact us for more information and updates, as well as for any inquiries regarding accessing to our innovative indexes.",
  },
  thematic: {
    title: "Unlock the potential of future trends!",
    body: "Contact us for more information and updates, as well as for any inquiries regarding accessing to thematic landscape.",
  },
  start: {
    title: "Get started!",
    body: "If you want to receive more information and updates, as well contact us for any inquiries regarding access to our platform and services:",
  },
  technology: {
    title: "Contact Us!",
    body: "Contact us for more information and updates, as well as for any inquiries regarding access to our technology infrastructure.",
  },
  ready_to_get_started: {
    title: "Ready to get started?",
    body: "Whether you're seeking more information, interested in our latest news and exciting updates, or have inquiries about access to BITACore, we're here for you.",
  },
};

const ContactUs: FC<Props> = ({ type, color }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.title}>{TEXTS[type].title}</Box>
        <Box className={classes.body}>{TEXTS[type].body}</Box>

        <ContactUsButton color={color} />
      </Box>
    </Box>
  );
};

export default ContactUs;
