import { FC } from "react";
import { Box, Link } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  image: any;
  type: string;
  title: string;
  link?: string;
}

const ThematicIndexesCarouselCard: FC<Props> = ({
  image,
  type,
  title,
  link = "#",
}) => {
  const classes = useStyles();

  return (
    <Link className={classes.link} href={link} target="_blank">
      <Box
        className={classes.container}
        sx={{
          backgroundImage: `url(${image})`,
        }}
      >
        <Box className={classes.typeWrapper}>
          <span className={classes.typeText}>{type}</span>
        </Box>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.learnMore}>Learn More &gt;</Box>
      </Box>
    </Link>
  );
};

export default ThematicIndexesCarouselCard;
