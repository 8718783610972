import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    position: "relative",
    margin: "0 14px",
    paddingBottom: "8rem",
    borderRadius: "1rem",
    overflow: "hidden",
  },
  cardTextMain: {
    position: "absolute",
    width: "100%",
    minHeight: "12rem",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    padding: "1.5rem 1.5rem 0.5rem",
    zIndex: 1,
    transition: "padding 1s, margin 5s, height 1s",
  },
  cardTitle: {
    fontSize: 25,
    lineHeight: 1.2,
    fontWeight: 600,
    paddingBottom: "0.75rem",
    maxWidth: "60%",
  },
  cardTextNotFlipped: { flex: 1, fontSize: 15, fontWeight: 500 },
  cardTextFlipped: {
    flex: 1,
    fontSize: 15,
    fontWeight: 500,
    paddingTop: "0.5rem",
    paddingLeft: "1rem",
    paddingBottom: "1rem",
  },
  list: { listStyle: "disc" },
  cardAction: {
    width: "max-content",
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid",
    marginBottom: 0,
  },
  iconWrapper: {
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "0.5rem",
  },
  footerActionLabel: {
    fontWeight: 600,
  },
}));
