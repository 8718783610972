import { useEffect, useLayoutEffect, useState } from "react";
import { Box } from "@mui/material";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useWindowSize } from "./hooks/useWindowSize";
import MainLayout from "./layouts/main";
import ProductsLayout from "./layouts/products";
import AboutUs from "./pages/About";
import AnnouncementsPage from "./pages/Announcements";
import ContactUs from "./pages/ContactUs";
import CookiePolicy from "./pages/CookiePolicy";
import Disclaimer from "./pages/Disclaimer";
import GovernancePage from "./pages/Governance";
import HomePage from "./pages/Home";
import Imprint from "./pages/Imprint";
import IndexPage from "./pages/Index";
import IndexDetailsPage from "./pages/IndexDetails";
import IndexServices from "./pages/IndexServices";
import PageIndex from "./pages/Legacy/PageIndex";
import PdfFilePage from "./pages/Legacy/PdfFile";
import Legal from "./pages/Legal";
import OurClients from "./pages/OurClients";
import PortfolioApisPage from "./pages/PortfolioApis";
import ProductsPage from "./pages/Products";
import CryptoOffering from "./pages/Products/CryptoOffering";
import ESGImpactData from "./pages/Products/ESGImpactData";
import IndexLab from "./pages/Products/IndexLab";
import ThematicData from "./pages/Products/ThematicData";
import SlaPage from "./pages/Sla";
import { checkFeatureFlagEnabled } from "./utils/env-flgs";

function App() {
  const { pathname } = useLocation();
  const [width, height] = useWindowSize();
  const [scrolled, setScrolled] = useState<boolean>(false);

  const handleScroll = () => {
    setScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrolled(false);
  }, [pathname]);

  useLayoutEffect(() => {
    if (height > 0) {
      setScrolled(window.scrollY > 0);
    }
  }, [height]);

  if (!width) return null;

  return (
    <Box className="page">
      <Routes>
        <Route path="products" element={<Header scrolled={scrolled} />} />
        <Route path="governance" element={<Header scrolled={scrolled} />} />
        <Route path="assets/files/:folder/:filename" element={null} />
        <Route path="/*" element={<Header scrolled={scrolled} />} />
      </Routes>

      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/index" element={<IndexPage />} />
          <Route path="/bita-indexes.html" element={<IndexPage />} />
          <Route path="/index/:id" element={<IndexDetailsPage />} />
          <Route path="/pages/:id" element={<PageIndex />} />
          <Route path="/announcements" element={<AnnouncementsPage />} />
          <Route path="/governance" element={<GovernancePage />} />
          <Route path="/governance.html" element={<GovernancePage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-of-use" element={<Legal />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route
            path="/pages/cookie-policy/cookie-policy.html"
            element={<CookiePolicy />}
          />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/indexing-apis" element={<PortfolioApisPage />} />
          <Route path="index-services" element={<IndexServices />} />
          <Route
            path="assets/files/:folder/:filename"
            element={<PdfFilePage />}
          />
          {checkFeatureFlagEnabled("ABOUT_US_PAGE_ENABLED") && (
            <Route path="about" element={<AboutUs />} />
          )}
          <Route path="/calculation_sla" element={<SlaPage />} />
          {checkFeatureFlagEnabled("CLIENTS_PAGE_ENABLED") && (
            <Route path="/clients" element={<OurClients />} />
          )}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
        <Route path="products" element={<ProductsLayout />}>
          <Route index element={<ProductsPage />} />
          <Route path="crypto-offering" element={<CryptoOffering />} />
          {checkFeatureFlagEnabled("INDEX_LAB_PAGE_ENABLED") && (
            <Route path="index-lab" element={<IndexLab />} />
          )}
          <Route path="thematic-data" element={<ThematicData />} />
          <Route path="esg-impact-data" element={<ESGImpactData />} />
        </Route>
      </Routes>

      <Routes>
        <Route
          path="contact-us"
          element={
            <Footer
              noContactUs
              colorScheme="dark_blue"
              mainContentColorScheme="light"
            />
          }
        />
        <Route
          path="index-services"
          element={
            <Footer
              contactType="technology"
              colorScheme="dark_blue"
              mainContentColorScheme="dark"
            />
          }
        />
        <Route path="products">
          <Route
            index
            element={
              <Footer
                contactType="start"
                colorScheme="gray"
                mainContentColorScheme="dark"
              />
            }
          />
          <Route
            path="crypto-offering"
            element={
              <Footer
                contactType="contact"
                colorScheme="gray"
                mainContentColorScheme="dark"
              />
            }
          />
          <Route
            path="index-lab"
            element={
              <Footer
                contactType="license_index"
                colorScheme="blue"
                mainContentColorScheme="dark"
              />
            }
          />
          <Route
            path="thematic-data"
            element={
              <Footer
                contactType="thematic"
                colorScheme="purple"
                mainContentColorScheme="dark"
              />
            }
          />
          <Route
            path="esg-impact-data"
            element={
              <Footer
                contactType="thematic"
                colorScheme="green"
                mainContentColorScheme="dark"
              />
            }
          />
        </Route>
        <Route
          path="portfolio-apis"
          element={
            <Footer
              contactType="start"
              colorScheme="light_blue"
              mainContentColorScheme="dark"
            />
          }
        />
        <Route
          path="index"
          element={
            <Footer colorScheme="dark_blue" mainContentColorScheme="light" />
          }
        />
        <Route
          path="index/:id"
          element={
            <Footer colorScheme="dark_blue" mainContentColorScheme="light" />
          }
        />
        <Route
          path="announcements"
          element={
            <Footer colorScheme="dark_blue" mainContentColorScheme="light" />
          }
        />
        <Route
          path="governance"
          element={
            <Footer colorScheme="dark_blue" mainContentColorScheme="light" />
          }
        />
        <Route
          index
          element={
            <Footer
              contactType="start"
              colorScheme="home"
              mainContentColorScheme="dark"
            />
          }
        />
        <Route path="assets/files/:folder/:filename" element={null} />
        <Route
          path="about"
          element={
            <Footer colorScheme="dark_blue" mainContentColorScheme="light" />
          }
        />
        <Route
          path="clients"
          element={
            <Footer
              contactType="ready_to_get_started"
              colorScheme="gray"
              mainContentColorScheme="light"
            />
          }
        />
        <Route
          path="/*"
          element={
            <Footer colorScheme="light_blue" mainContentColorScheme="light" />
          }
        />
      </Routes>
    </Box>
  );
}

export default App;
