import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: "#fff !important",
    textDecoration: "none !important",
  },
  container: {
    position: "relative",
    height: "18rem",
    display: "flex",
    flexDirection: "column",
    margin: "0 14px",
    padding: "1.25rem",
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  typeWrapper: {
    fontSize: 11,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  typeText: {
    borderBottom: "1px solid #fff",
    paddingBottom: 4,
  },
  title: {
    maxWidth: "70%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: 600,
    paddingBottom: "0.75rem",
  },
  learnMore: {
    fontSize: 13,
    fontWeight: 700,
  },
}));
