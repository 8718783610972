import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Container, IconButton, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import IconLogo from "../../assets/images/icons/Logo.svg";
import { IMenuItem } from "../../types/ui";
import { checkFeatureFlagEnabled } from "../../utils/env-flgs";
import ContactUsButton from "../ContactUsButton";
import HeaderMenu from "./HeaderMenu";
import MobileMenu from "./MobileMenu";
import { useStyles } from "./styles";

const HEADER_ITEMS: IMenuItem[] = [
  {
    id: "indexing",
    label: "Indexing",
    items: [
      {
        id: "index",
        label: "BITA Indexes",
        href: "/index",
      },
      {
        id: "index-services",
        label: "Index Services",
        href: "/index-services",
      },
      {
        id: "crypto",
        label: "Crypto Offering",
        href: "/products/crypto-offering",
        hidden: !checkFeatureFlagEnabled("CRYPTO_OFFERING_PAGE_ENABLED"),
      },
      {
        id: "indexlab",
        label: "Index Lab",
        href: "/products/index-lab",
        hidden: !checkFeatureFlagEnabled("INDEX_LAB_PAGE_ENABLED"),
      },
    ],
  },
  {
    id: "bitacore",
    label: "BITACore",
    href: "/products",
  },
  {
    id: "thematics",
    label: "Thematics",
    href: "/products/thematic-data",
  },
  {
    id: "esg-impact",
    label: "ESG & Impact",
    href: "/products/esg-impact-data",
  },
  {
    id: "portfolio-apis",
    label: "Indexing APIs",
    href: "/indexing-apis",
  },
  {
    id: "more",
    label: "More",
    items: [
      {
        id: "about",
        label: "About BITA",
        href: "/about",
        hidden: !checkFeatureFlagEnabled("ABOUT_US_PAGE_ENABLED"),
      },
      {
        id: "clients",
        label: "Our Clients",
        href: "/clients",
        hidden: !checkFeatureFlagEnabled("CLIENTS_PAGE_ENABLED"),
      },
      {
        id: "governance",
        label: "Governance",
        href: "/governance",
      },
      {
        id: "announcements",
        label: "Announcements",
        href: "/announcements",
      },
    ],
  },
];

interface Props {
  color?: string;
  scrolled: boolean;
}

const Header: React.FC<Props> = ({ color = "#25246D", scrolled }) => {
  const classes = useStyles();
  const location = useLocation();

  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuVisible(true);
  };

  const closeMenu = () => {
    setMobileMenuVisible(false);
  };

  const isInContactPage = location.pathname === "/contact-us";

  const MOBILE_ITEMS = [
    {
      id: "home",
      label: "Home",
      href: "/",
    },
    ...HEADER_ITEMS,
  ];

  return (
    <Box className="header">
      {/* mobile menu */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <AppBar
          position="fixed"
          className={`${classes.mobileAppBar} ${
            scrolled ? classes.mobileScrolled : ""
          }`}
        >
          <Toolbar>
            <Box className={classes.mobileMenuContainer}>
              <Box>
                <Link to="/">
                  <img src={IconLogo} alt="BITA Logo" />
                </Link>
              </Box>
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={openMenu}
              >
                <MenuIcon />
              </IconButton>
              <MobileMenu
                open={mobileMenuVisible}
                items={MOBILE_ITEMS}
                onClose={closeMenu}
              />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      {/* desktop menu */}
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Box className={`${classes.root} ${scrolled ? classes.scrolled : ""}`}>
          <Box className={classes.logoWrapper}>
            <Link to="/">
              <img src={IconLogo} alt="BITA Logo" />
            </Link>
          </Box>
          <Container className={classes.navContainer}>
            <Box className={classes.navList}>
              {HEADER_ITEMS.map(({ id, label, href, items, hidden }) =>
                hidden ? null : (
                  <Box key={label} className={classes.navItem}>
                    {items?.length ? (
                      <HeaderMenu key={id} label={label} items={items} />
                    ) : (
                      <Link to={href ?? "#"}>{label}</Link>
                    )}
                  </Box>
                )
              )}
            </Box>
          </Container>

          {isInContactPage ? null : <ContactUsButton color={color} />}
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
