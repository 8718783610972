import { Box } from "@mui/material";
import Img1 from "../../assets/images/products/crypto-offering/card1.png";
import Img2 from "../../assets/images/products/crypto-offering/card2.png";
import Img3 from "../../assets/images/products/crypto-offering/card3.png";
import Img4 from "../../assets/images/products/crypto-offering/card4.png";
import Carousel from "../Carousel";
import ThematicIndexesCarouselCard from "../ThematicIndexesCarousel/Card";

const CryptoOfferingCarousel = () => {
  return (
    <Box pt={3}>
      <Carousel options={{ infinite: true }}>
        <ThematicIndexesCarouselCard
          image={Img1}
          type="Equity Index"
          title="BITA Crypto 10"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img2}
          type="Equity Index"
          title="BITA Crypto Decentralized Finance Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img3}
          type="Equity Index"
          title="BITA Crypto Metaverse Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img4}
          type="Equity Index"
          title="BITA Crypto Smart Contract Platforms Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img1}
          type="Equity Index"
          title="BITA Crypto 10"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img2}
          type="Equity Index"
          title="BITA Crypto Decentralized Finance Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img3}
          type="Equity Index"
          title="BITA Crypto Metaverse Index"
          link=""
        />
        <ThematicIndexesCarouselCard
          image={Img4}
          type="Equity Index"
          title="BITA Crypto Smart Contract Platforms Index"
          link=""
        />
      </Carousel>
    </Box>
  );
};

export default CryptoOfferingCarousel;
