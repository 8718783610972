import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  text: {
    maxWidth: theme.spacing(90),
    fontSize: 20,
    lineHeight: 1.2,
  },
  main: {
    padding: "6rem 0",
    backgroundColor: "#1B1B1B",
  },
  mainTopSection: {
    paddingBottom: "2rem",
  },
  mainText: {
    fontSize: 20,
    lineHeight: 1.2,
  },
  sectionContainer: {
    padding: "1rem 0rem 1rem",
  },
  heroSection: {
    width: "100%",
    padding: "7.5rem 12rem",
    margin: "6rem 0",
    color: "#000",

    [theme.breakpoints.down("md")]: {
      color: "#fff",
      padding: 0,
      margin: "3rem 0",
    },
  },
  heroSectionContainer: {
    [theme.breakpoints.down("md")]: {
      padding: "3rem 0",
      borderTop: "1px solid #fff",
      borderBottom: "1px solid #fff",
    },
  },
}));
