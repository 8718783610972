import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  title: {
    padding: "1rem",
    background: theme.palette.primary.main,
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "0.875rem",
    fontWeight: 600,
  },
}));
