import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  section: {
    padding: "2.5rem 0",
    alignItems: "center",

    "&.reverse": {
      flexDirection: "row-reverse",
    },
  },
  sectionContent: {
    [theme.breakpoints.down("md")]: {
      paddingTop: "2rem",
    },

    "&.standard": {
      paddingRight: "3rem",

      [theme.breakpoints.down("md")]: {
        paddingRight: 0,
      },
    },
  },
  sectionSubTitle: {
    color: "#698BEC",
    fontSize: 15,
    fontWeight: 700,
  },
  sectionSubTitleBottom: {
    width: "4rem",
    height: 1,
    background: "#698BEC",
    margin: "0.25rem 0 0.5rem",
  },
  sectionTitle: {
    fontSize: 30,
    fontWeight: 600,
    paddingBottom: "0.5rem",
  },
  sectionText: {
    padding: "0.5rem 0",
  },
  sectionImage: {
    "& img": {
      margin: "0 auto",
    },
  },
  divider: {
    display: "none",
    width: "100%",
    height: 2,
    marginBottom: "3.75rem",
    background: "linear-gradient(360deg, #4995FF 0%, #235CFF 100%)",

    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
}));
