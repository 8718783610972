import { FC } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import { BreadcrumbsItem } from "../../types/ui";
import { useStyles } from "./styles";

interface Props {
  items: BreadcrumbsItem[];
}

const BitaBreadcrumbs: FC<Props> = ({ items }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {items.map(({ key, label, to }, idx) => (
        // Use a conditional rendering to ensure `to` is defined before passing to Link
        to ? (
          <Link
            key={key}
            className={classes.link}
            to={to}
            color="inherit"
            style={{ fontWeight: idx === items.length - 1 ? "700" : "500" }}
          >
            {label}
          </Link>
        ) : (
          // Render label as a plain text span when `to` is undefined
          <span key={key} className={classes.link} style={{ fontWeight: idx === items.length - 1 ? "700" : "500" }}>
            {label}
          </span>
        )
      ))}
    </Breadcrumbs>
  );
};
export default BitaBreadcrumbs;
