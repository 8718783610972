import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  headerMask: {
    background: "#5EB2FF",
    position: "absolute",
    width: "100%",
    height: 26,
    left: 0,
    bottom: 12,
  },
  headerMask2: {
    background: "#A3D3FF",
    position: "absolute",
    width: "100%",
    height: 12,
    left: 0,
    bottom: 0,
  },
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  containerWrapper: {},
  text: {
    maxWidth: theme.spacing(90),
    fontSize: 20,
    lineHeight: 1.2,
  },
  main: {
    padding: "3rem 0",
  },
  innerContainer: {
    margin: "0 -9.5rem",
    [theme.breakpoints.down("xl")]: {
      margin: "0 auto",
    },
  },
  mainHeading: {
    ...pageStyles(theme).mainHeading,
    color: theme.palette.primary.main,
  },
  legalContainer: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  withBorderBottom: {
    borderBottomWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
}));
