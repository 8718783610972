export const downloadFileInBrowser = (
  blob: Blob,
  fileName: string,
  shouldOpenAfterDownload: boolean,
  type: string
) => {
  let file
 type === "pdf" 
 ? file = new Blob([blob],{ type: 'application/pdf' }) 
 : file = new Blob([blob])
 
  const link = document.createElement("a");
  const fileLink = URL.createObjectURL(file);
  // create a blobURI pointing to our Blob
  link.href = fileLink;
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);

  link.click();

  if (shouldOpenAfterDownload) {
    window.open(fileLink, "_blank");
  }

  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 5000);
};
