import { FamiliesIndexes } from "../../types";
import {
  AssetClassOption,
  IndexEod,
  IndexFile,
  IndexHistory,
  IndexItemData,
  IndexLab,
  IndexListItem,
  IndexType,
  IndexTypeOption,
  IndexValue,
  IndexValueFirstSecond,
  IndexWithWeight,
} from "../../types/indexes";
import api from "../api";

export const getFamiliesIndexes = async (): Promise<{
  data: FamiliesIndexes;
  type: IndexType;
}> => {
  const { data } = await api.get("/v1/families_indexes");
  return data;
};

export const getIndexesWithWeight = async (): Promise<{
  data: IndexWithWeight[];
  type: IndexType;
}> => {
  const { data } = await api.get("/v1/indexes_with_weight");
  return data;
};

export const getIndexesEOD = async (
  indexes: string[],
  startDate: string,
  endDate: string
): Promise<{
  data: IndexEod;
  type: IndexType;
}> => {
  const { data } = await api.post("/v1/indexes_eod", {
    indexes,
    start_date: startDate,
    end_date: endDate,
  });

  // sometimes IndexEodItem.value could be string type,
  // so, we need to format the data
  return {
    ...data,
    data: Object.keys(data.data).reduce((acc, currentKey) => {
      return {
        ...acc,
        [currentKey]: data.data[currentKey]?.map((item: any) => ({
          ...item,
          value: parseFloat(item.value),
        })),
      };
    }, {}),
  };
};

export const getIndexHistory = async (
  indexId: string,
  startDate: string,
  endDate: string,
  frequency: string
): Promise<IndexHistory> => {
  const { data } = await api.post("/v1/index_history", {
    index_id: indexId,
    start_date: startDate,
    end_date: endDate,
    frequency,
  });
  return data;
};

export const getTokenPriceIndexEOD = async (
  assets: string[],
  startDate: string,
  endDate: string
): Promise<{
  data: IndexEod;
  type: IndexType;
}> => {
  const { data } = await api.post("/v2/token_price_index_eod", {
    assets,
    start_date: startDate,
    end_date: endDate,
  });

  return {
    ...data,
    data: Object.keys(data.data).reduce((acc, currentKey) => {
      return {
        ...acc,
        [currentKey]: data.data[currentKey]?.map((item: any) => ({
          ...item,
          value: parseFloat(item.value),
        })),
      };
    }, {}),
  };
};

export const getIndexValues = async (
  indexes: string[]
): Promise<{
  data: IndexValue[];
  type: IndexType;
}> => {
  const { data } = await api.post("/v1/index_value", {
    indexes,
  });
  return data;
};

export const getIndexValuesFirstSecond = async (): Promise<{
  data: IndexValueFirstSecond[];
}> => {
  const { data } = await api.get("/v1/index_value_first_second");
  return data;
};

export const getV2IndexList = async (): Promise<{
  data: IndexListItem[];
  type: IndexType;
}> => {
  const { data } = await api.get("/v2/index/list");
  return data;
};

export const getV2IndexItemData = async (indexId: string): Promise<{
  data: IndexItemData;
}> => {
  const { data } = await api.get(`/v2/index/${indexId}`);
  return data;
};

export const getV2IndexFile = async (
  indexId: string
): Promise<{
  data: IndexFile[];
}> => {
  const { data } = await api.get(`/v2/index/files?index_id=${indexId}`);
  return data;
};

export const getConstituentsFile = async (indexId: string): Promise<Blob> => {
  const { data } = await api.get(`/v1/constituents_file?index_id=${indexId}`, {
    responseType: "blob", // "responseType" should be set to "blob" to indicate this is file download
  });

  return data;
};

export const getPdfFile = async (url: string): Promise<Blob> => {
  const req  = await fetch(url, { mode: 'cors',
  headers: {
    "Accept": "application/pdf",
  }});
  const data = req.blob()
  return data;
};

export const downloadFile = async (fileId: string): Promise<Blob> => {
  const { data } = await api.get(`/v2/index/file?file_id=${fileId}`, {
    responseType: "blob", // "responseType" should be set to "blob" to indicate this is file download
  });
  return data;
};

export const getAssetClassOptions = async (): Promise<{
  data: AssetClassOption[];
}> => {
  const { data } = await api.get("/v2/index/asset-class");
  return data;
};

export const getAssetIndexTypeOptions = async (): Promise<{
  data: IndexTypeOption[];
}> => {
  const { data } = await api.get("/v2/index/index-type");
  return data;
};

export const getIndexLabs = async (): Promise<{
  data: IndexLab[];
}> => {
  const { data } = await api.get("/v2/index-lab");
  return data;
};
