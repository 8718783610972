import { FC } from "react";
import { Box } from "@mui/material";
import moment from "moment";
import { News } from "../../types/news";
import Carousel from "../Carousel";
import HomeCard from "../HomeCard";

interface Props {
  data: News[];
}

const HomeCardCarousel: FC<Props> = ({ data }) => {
  return (
    <Carousel>
      {data.map(
        ({
          id_m_web_news,
          categorization,
          createdate,
          title,
          link_media,
          preview_image,
        }) => (
          <Box key={id_m_web_news}>
            <HomeCard
              type={categorization?.name ?? "Unknown Type"}
              title={title}
              date={moment(createdate).format("MMMM yyyy")}
              bgImage={preview_image}
              link={link_media}
            />
          </Box>
        )
      )}
    </Carousel>
  );
};

export default HomeCardCarousel;
