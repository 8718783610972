import { Box } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  icon: React.ReactElement;
  title: string[];
  content: string;
  color?: string;
  background?: string;
}

const ThematicDataCard: React.FC<Props> = ({
  icon,
  title,
  content,
  color = "#fff",
  background = "#fff",
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      sx={{
        borderColor: color,
      }}
    >
      <Box
        className={classes.title}
        sx={{
          background,
          color,
          backgroundClip: "text",
        }}
      >
        <Box className={classes.icon}>{icon}</Box>
        <Box className={classes.label}>
          {title.map((str, idx) => (
            <Box key={str + idx}>{str}</Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.content}>{content}</Box>
    </Box>
  );
};

export default ThematicDataCard;
