import { FC } from "react";
import { Box, Grid } from "@mui/material";
import ExternalLink from "../ExternalLink";
import { useStyles } from "./styles";

interface Props {
  subTitle: string;
  title: string;
  text: string[];
  image: any;
  direction?: "reverse" | "standard";
}

const IndexServiceSection: FC<Props> = ({
  subTitle,
  title,
  text,
  image,
  direction = "standard",
}) => {
  const classes = useStyles();

  return (
    <Grid container className={`${classes.section} ${direction}`}>
      <Box className={classes.divider} />
      <Grid item xs={12} sm={12} md={7} className={classes.sectionImage}>
        <img src={image} alt={title} />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        className={`${classes.sectionContent} ${direction}`}
      >
        <Box className={classes.sectionSubTitle}>{subTitle}</Box>
        <Box className={classes.sectionSubTitleBottom} />
        <Box className={classes.sectionTitle}>{title}</Box>

        {text.map((t, idx) => (
          <Box key={t + idx} className={classes.sectionText}>
            {t}
          </Box>
        ))}

        <ExternalLink label="Contact Us" link="/contact-us" />
      </Grid>
    </Grid>
  );
};

export default IndexServiceSection;
