import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  text: {
    maxWidth: theme.spacing(90),
    fontSize: 20,
    lineHeight: 1.2,
  },
  main: {
    paddingTop: "6rem",
    paddingBottom: "4rem",
    backgroundColor: "#1b1b1b",

    [theme.breakpoints.down("sm")]: {
      paddingTop: "4rem",
    },

    "& .MuiTable-root": {
      "& .MuiTableCell-head": {
        backgroundColor: "#235CFF",
      },
      "& .MuiTableRow-root": {
        backgroundColor: "transparent",
      },
      "& .MuiTableCell-body": {
        color: "#fff",
        borderColor: "#235CFF",
      },
    },
    "& .MuiTablePagination-root": {
      color: "#fff",
    },
    "& .MuiTablePagination-actions .MuiButtonBase-root": {
      color: "#fff",
    },
  },
  mainHeading: {
    ...pageStyles(theme).mainHeading,
    fontWeight: 700,
  },
  mainText: {
    ...pageStyles(theme).mainText,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      marginTop: "0.5rem",
      paddingBottom: "1.5rem",
    },
  },
  mainTopSection: {
    borderBottom: "2px solid #fff",
    paddingBottom: "3rem",
  },
  sectionContainer: {
    padding: "1rem 0rem 1rem",

    "& .MuiPaginationItem-root": {
      color: "#fff",
    },
  },
  selectsContainer: {
    paddingBottom: "1rem",
    display: "flex",
    gap: "1.25rem",
  },
  highlighted: {
    position: "relative",
    paddingTop: "1.5rem",
    margin: "3rem 0",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  highlightedText: {
    paddingLeft: "3rem",
    fontSize: "2.5rem",
    fontWeight: 600,

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5rem",
    },
  },
  highlightedBox: {
    position: "relative",
    paddingTop: "4rem",
    bottom: 0,
    right: 0,
  },
  sectionFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  displayingInfo: {
    fontWeight: 600,
    fontSize: "1rem",
  },
  heroInnerContainer: {
    paddingTop: "4.5rem",
    paddingBottom: "4.5rem",
    paddingLeft: "9.5rem",
    marginLeft: "-9.5rem",
    marginRight: "-9.5rem",
    width: "calc(100% + 20vw)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      padding: 0,
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
      backgroundImage: "none !important",
      borderTopWidth: 2,
      borderBottomWidth: 2,
      borderColor: "#fff",
    },
  },
  heroHeading: {
    maxWidth: "26rem",
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: 1.35,
  },
  heroText: {
    padding: "2rem 0 1.5rem",
    fontSize: "1.5rem",
    maxWidth: "25rem",
  },
  carouselSection: {
    padding: "3rem 0",
  },
  carouselSectionHeading: {
    fontSize: "3rem",
    fontWeight: 600,
    paddingBottom: "2.5rem",
  },
}));
