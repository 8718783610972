import { AnyAction, Dispatch, MiddlewareAPI } from "@reduxjs/toolkit";
import isEqual from "lodash.isequal";

export const customMiddleware =
  (store: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    const currentState = store.getState();

    // Check if the action has a payload
    if (action.payload?.data) {
      // Get the slice and field names of data related to the action
      const [sliceName, fieldName] = action.type.split("/");

      // Get the current state for the slice
      const currentSliceState = currentState[sliceName][fieldName];

      // Compare the payload with the current slice state
      const isSameState = isEqual(action.payload.data, currentSliceState);

      // If the payload is the same as the current state, return the current state
      if (isSameState) {
        return currentSliceState;
      }
    }

    // If the payload is not the same, proceed with the normal action dispatch
    return next(action);
  };
