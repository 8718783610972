import { Box } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  city: string;
  country: string;
  image: any;
  address: string;
}

const HeadquarterCard: React.FC<Props> = ({
  country,
  city,
  image,
  address,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      sx={{
        backgroundImage: `url(${image})`,
      }}
    >
      <Box className={classes.main}>
        <Box className={classes.city}>{city}</Box>
        <Box className={classes.country}>{country}</Box>
      </Box>
      <Box className={classes.address}>{address}</Box>
    </Box>
  );
};

export default HeadquarterCard;
