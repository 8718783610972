import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  button: {
    "& .MuiButton-root": {
      borderRadius: "0.5rem",
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
  contactButton: {
    "&.MuiButton-root": {
      borderWidth: 1,
      borderColor: "#235CFF",
      borderStyle: "solid",
      fontWeight: 600,
      "&:hover": {
        color: "#fff",
        backgroundColor: "transparent",
        borderColor: "#fff",
      },
      "&:active": {
        color: "#fff",
        backgroundColor: "#235CFF",
        borderColor: "#235CFF",
      },
    },
  },
});
