import { FC } from "react";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { IMenuItem } from "../../types/ui";

interface Props extends IMenuItem {
  open: boolean;
  onOpen: (id: string) => void;
  onClose: () => void;
  onClickItem: (href?: string) => void;
}

const MobileDropdown: FC<Props> = ({
  open,
  id,
  label,
  href,
  items,
  hidden,
  onOpen,
  onClose,
  onClickItem,
}) => {
  const { pathname } = useLocation();

  if (hidden) return null;

  const toggle = () => {
    if (open) {
      onClose();
    } else {
      onOpen(id);
    }
  };

  const hasSubMenu = !!items?.length;
  const iconProps = {
    size: "small",
    htmlColor: "#fff",
  };

  return (
    <Box>
      <Box
        className={`item ${open || pathname === href ? "activeItem" : ""}`}
        onClick={href ? () => onClickItem(href) : toggle}
      >
        <Box className="label">{label}</Box>
        {hasSubMenu && (
          <Box>
            {open ? (
              <ArrowDropUp {...iconProps} />
            ) : (
              <ArrowDropDown {...iconProps} />
            )}
          </Box>
        )}
      </Box>
      {open &&
        hasSubMenu &&
        items.map(
          ({ id, label, href, hidden }) =>
            !hidden && (
              <Box
                key={id}
                className={`subItem ${
                  href === pathname ? "activeSubItem" : ""
                }`}
                onClick={() => onClickItem(href)}
              >
                {label}
              </Box>
            )
        )}
    </Box>
  );
};

export default MobileDropdown;
