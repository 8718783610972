import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    position: "relative",
    padding: "2rem 6rem",

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: 14,
    },
  },
  container: {
    color: "#fff",
    padding: "2.5rem 0",
    borderTop: "1px solid #fff",
    borderBottom: "1px solid #fff",
  },
  logoWrapper: {},
  main: {
    display: "flex",
    justifyContent: "space-between",
  },
  navSection: {
    paddingRight: "2rem",
  },
  navList: {
    paddingTop: "0.1rem",
  },
  title: {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "1.25rem",
  },
  navItem: {
    color: "#fff",
    fontSize: 15,
    lineHeight: "18px",
    "& a": {
      color: "#fff",
      textDecoration: "none",
    },
  },
  copyright: {
    paddingTop: "1.5rem",
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "1.25rem",
    textAlign: "center",
    color: "#fff",
  },
  link: {},

  // mobile styles
  mobileRoot: {
    position: "relative",
    padding: "3rem",
    color: "#fff",

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: 24,
    },
  },
  mobileFollowUs: {
    fontSize: "1.25rem",
    fontWeight: 700,
    textAlign: "center",
    paddingBottom: "1.5rem",
  },
  mobileLogo: {
    margin: "0 auto",
  },
  mobileAccordion: {
    "&.MuiAccordion-root": {
      backgroundColor: "transparent",
      boxShadow: "none",

      "&::before": {
        backgroundColor: "#fff",
        opacity: 1,
      },

      "&.Mui-expanded:before": {
        opacity: 1,
      },
    },
  },
  mobileAccordionSummary: {
    "&.MuiAccordionSummary-root": {
      color: "#fff",
      fontSize: "1.25rem",
      fontWeight: 700,
      padding: "0.5rem 0",
    },
  },
  mobileAccordionDetails: {
    "&.MuiAccordionDetails-root": {
      padding: 0,
    },
  },
  mobileNavItem: {
    padding: "0.5rem",
  },
});
