import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  white: {
    "& .MuiInputBase-root": {
      color: "#fff",

      "& .MuiInputAdornment-root svg": {
        fill: "#fff",
      },
      "&:after": {
        borderColor: "#fff",
      },
      "&:before": {
        borderColor: "rgba(255, 255, 255, 0.42)",
      },
      "&:hover:before": {
        borderBottom: "2px solid #fff !important",
      },
    },
  },
}));
