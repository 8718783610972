import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    position: "fixed",
    width: "100%",
    height: "12.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "6rem 1.5rem 3rem",
    background: "transparent",
    zIndex: 1,
    transition: "all 0.3s",
  },
  scrolled: {
    height: "auto",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    background: "rgba(0, 0, 0, 0.92)",
  },
  logoWrapper: {
    "& img": {
      maxWidth: "120%",
      width: "120%",
    },
  },
  navContainer: {
    flex: 1,
    "&.MuiContainer-root": {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  navList: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  navItem: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    color: "#fff",
    fontSize: "1rem",
    lineHeight: "18px",
  },
  menuButton: {
    "&.MuiButton-root": {
      color: "#fff",
      fontSize: 15,
      fontWeight: 400,
      textTransform: "none",
    },
  },
  menu: {
    "& .MuiPaper-root": {
      background:
        "radial-gradient(100% 100% at 49.76% 100%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(100% 197.64% at 2.09% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.05) 100%)",
      backdropFilter: "blur(12.5px)",
      borderRadius: 8,
      borderWidth: "1px",
      borderStyle: "solid",
      borderImageSource:
        "radial-gradient(80.45% 162.13% at 0% 0%, #FFFFFF 0%, rgba(155, 181, 255, 0.5) 32.53%, rgba(155, 181, 255, 0.2) 64.91%, rgba(255, 255, 255, 0) 100%)",
      boxShadow: "5px 5px 34px -10px rgba(0, 0, 0, 0.25)",
    },
  },
  menuItem: {
    "&.MuiMenuItem-root": {
      color: "#fff",
      fontSize: "15px",
      fontWeight: 400,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      },
    },
  },
  mobileAppBar: {
    "&.MuiAppBar-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      transition: "all 0.3s",
    },
  },
  mobileMenuContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2rem 1.5rem",
  },
  mobileScrolled: {
    "&.MuiAppBar-root": {
      backgroundColor: "rgba(0, 0, 0, 0.92)",
    },
  },
  mobileMenu: {
    width: "100vw",
  },
  mobileMenuItem: {
    width: "100%",
  },
  mobileMenuSubItem: {
    "&.MuiMenuItem-root": {
      paddingLeft: "2rem",
    },
  },
});
