import { useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import ImgEarth from "../../assets/images/portfolio-apis/earth.svg";
import ImgPattern from "../../assets/images/portfolio-apis/pattern.svg";
import PageHero from "../../components/PageHero";
import PortfolioApiCard from "../../components/PortfolioApiCard";

import { useStyles } from "./styles";

const PortfolioApisPage = () => {
  const classes = useStyles();

  const [circleVisible, setCircleVisible] = useState<boolean>(false);
  const [mousePos, setMousePos] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const handleMouseMove = (ev: React.MouseEvent) => {
    if (!circleVisible) setCircleVisible(true);
    setMousePos({ x: ev.pageX, y: ev.pageY });
  };

  const handleMouseOut = () => setCircleVisible(false);

  return (
    <Box className={classes.root}>
      <PageHero
        colorScheme="light_blue"
        mainContentColorScheme="dark"
        title="Indexing API’s"
        subTitle="Welcome to the future of investment customization with our suite of REST APIs."
      />

      <Box
        className={classes.main}
        sx={{
          backgroundImage: { xs: "none", md: `url(${ImgPattern})` },
        }}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
      >
        {/* {circleVisible && (
          <Box
            className={classes.movingCircle}
            sx={{
              top: mousePos.y,
              left: mousePos.x,
            }}
          />
        )} */}
        <Box position="relative">
          <Box
            className={classes.mobileBg}
            sx={{
              backgroundImage: { xs: `url(${ImgEarth})`, md: "none" },
            }}
          ></Box>
          <Container className={classes.mainContainer}>
            <Grid container spacing={2}>
              <Grid className={classes.mainContent} item xs={12} md={6}>
                <Box>
                  <Box className={classes.mainTitle}>
                    <Box className={classes.mainTitleTopLine} />
                    Unlimited Possibilities
                  </Box>
                  <Box className={classes.mainDescription}>
                    Our suite of Indexing APIs enable full access to BITA’s
                    investment universe customization workflows, indexing
                    solutions, rebalancing engine, and analytics and reporting
                    modules.
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.imgWrapper}>
                  <img src={ImgEarth} alt="Earth" />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container>
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <PortfolioApiCard
                  title="Investment Universe Construction API"
                  text="Slice and dice based on geography, sectors, themes, ESG scores, fundamentals, factors, and more."
                  link="https://universe-builder-api.bita.io/redoc"
                />
                <PortfolioApiCard
                  title="Analytics API"
                  text="Unparalleled point-in-time insights of your index. From Performance Attribution, Countries & Sectors, all the way to aggregated ESG ratings, Controversies, and Environmental Performance."
                  link="https://analyzer-api.bita.io/redoc"
                />
                <PortfolioApiCard
                  title="Factsheets API"
                  text="Create beautiful and insightful factsheets on-the-fly based on your baskets and indexes. Use your own branding, and customize your texts and messages."
                  link=""
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    paddingTop: { xs: 0, md: "4rem" },
                    marginTop: { xs: "-4rem", md: 0 },
                  }}
                ></Box>
                <PortfolioApiCard
                  title="Backtesting API"
                  text="Build your methodology. Parametrize rebalancing dates, weighting and ranking mechanisms, caps & floors, deletion rules, and much more. Then backtest in seconds."
                  link="https://backtester-api.bita.io/redoc"
                />
                <PortfolioApiCard
                  title="Thematics API"
                  text="Get a granular lens on the Thematic Exposures of your portfolio. Leverage BITA’s Thematic and Impact Research to uncover your exposures to megatrends, themes, subthemes, and activities."
                  link=""
                />
                <PortfolioApiCard
                  title="Reference Data API"
                  text="Get up-to-date, clean, reference data in a convenient way. Our API covers more than 30k instruments, including historical and point-in-time financial identifiers."
                  link=""
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default PortfolioApisPage;
