import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {
    paddingBottom: theme.spacing(10),
  },
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  text: {
    maxWidth: theme.spacing(90),
    fontSize: 20,
    lineHeight: 1.2,
  },
  main: {
    padding: "5rem 6rem 0",

    [theme.breakpoints.down("md")]: {
      padding: "2rem 3rem 0",
    },
  },
  mainHeading: {
    fontSize: 40,
    fontWeight: 600,
  },
  mainSubHeading: {
    fontSize: 25,
    fontWeight: 500,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  mainText: {
    fontSize: 25,
  },
  sectionContainer: {
    padding: "2rem 0",
  },
  sectionBorder: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(4),
      borderBottom: "1px solid #fff",
    },
  },
  imgContainer: {
    padding: "3rem 0",
    textAlign: "center",

    "& img": {
      margin: "0 auto",
    },
  },
  bottomSectionInner: {
    padding: "1.5rem 2rem",
    marginTop: "6rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  bottomSectionText: {
    marginTop: "1rem",
    fontSize: 18,
    lineHeight: 1.2,
  },
  bottomSectionLink: {
    marginTop: "3rem",
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 600,
    color: "#fff",
  },
  hiddenInMobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));
