import { FC } from "react";
import { Box, Button as DefaultButton, ButtonProps } from "@mui/material";
import { useStyles } from "./styles";

interface Props extends ButtonProps {
  label: string;
  icon?: JSX.Element;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button: FC<Props> = ({
  label,
  icon,
  variant,
  className,
  color,
  onClick,
  sx,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <DefaultButton
      variant="contained"
      className={`${className ?? ""} ${classes.main}`}
      sx={{
        bgcolor: color === "primary" ? "primary.main" : "#fff",
        color: color === "primary" ? "#fff" : "#000",
        borderRadius: 0,
        fontSize: 18,
        ":hover": {
          bgcolor: color === "primary" ? "#fff" : "#0031d9",
          color: color === "primary" ? "#0031d9" : "#fff",
        },
        ...sx,
      }}
      onClick={onClick}
      {...otherProps}
    >
      <Box className={classes.label}>
        {icon}
        {label}
      </Box>
    </DefaultButton>
  );
};

export default Button;
