import { FC } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { RadioButtonProps } from "../../types/ui";
import { useStyles } from "./styles";

interface Props {
  items: RadioButtonProps[];
  currentValue: string | number;
  onChange: (value: string | number) => void;
}

const RadioButtons: FC<Props> = ({ items, currentValue, onChange }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {items.map(({ label, value, disabled }) => (
        <Button
          className={classes.button}
          key={value}
          variant="contained"
          disabled={!!disabled}
          onClick={() => onChange(value)}
          sx={{
            backgroundColor:
              currentValue === value ? theme.palette.primary.main : "#C1CFF9",
          }}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};

export default RadioButtons;
