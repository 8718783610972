import { FC } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const MainLayout: FC<{}> = () => {
  return (
    <Box className="body">
      <Outlet />
    </Box>
  );
};

export default MainLayout;
