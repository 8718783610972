import ImgBarcelona from "../../assets/images/contact-us/barcelona.png";
import ImgCaracas from "../../assets/images/contact-us/caracas.png";
import ImgFrankfurt from "../../assets/images/contact-us/frankfurt.png";
import ImgNewYork from "../../assets/images/contact-us/newyork.png";

export const HEADQUARTERS = [
  {
    id: "0",
    city: "Frankfurt",
    country: "Germany",
    address: "Mainzer Landstraße 51, Frankfurt, Hesse 60329, DE",
    image: ImgFrankfurt,
  },
  {
    id: "1",
    city: "Barcelona",
    country: "Spain",
    address: "Mallorca 221, 4o 1a, Barcelona City 08008",
    image: ImgBarcelona,
  },
  {
    id: "2",
    city: "New York",
    country: "United States",
    address: "BITA, WeWork, 28-07 Jackson Ave Long Island City, NY 11101",
    image: ImgNewYork,
  },
  {
    id: "3",
    city: "Caracas",
    country: "Venezuela",
    address:
      "Av. Casanova, Centro Comercial El Recreo, Torre Norte, Piso 6, Oficina 8. Caracas 1050",
    image: ImgCaracas,
  },
];
