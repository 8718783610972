import { FC } from "react";
import { Box } from "@mui/material";
import ImgArrows from "../../assets/images/products/arrows.svg";
import { useStyles } from "./styles";

interface Props {
  title: string;
  text: string;
}

const BitaCoreListItem: FC<Props> = ({ title, text }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.wrapper}
      sx={{
        paddingRight: { xs: 0, md: "4rem" },
        margin: { xs: "0.75rem 0", md: "1rem 0" },
      }}
    >
      <Box className={classes.imgWrapper}>
        <img src={ImgArrows} alt="Arrows" />
      </Box>
      <Box className={classes.title}>{title}</Box>
      <Box>{text}</Box>
    </Box>
  );
};

export default BitaCoreListItem;
