import { FC, memo, useEffect, useMemo, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, CircularProgress, Grid } from "@mui/material";
import ImgBgPlaceholder from "../../assets/images/products/crypto-offering/Crypto-Bg-Placeholder.svg";
import { MultiCalc } from "../../types/analyzer";
import { formatPercentage, isNumeric } from "../../utils/numeric";
import Chart from "../Chart";
import ExternalLink from "../ExternalLink";
import { useStyles } from "./styles";

interface Props {
  id: number;
  title: string;
  description: string;
  bgImg: string;
  chartData: number[][];
  multiCalcData: MultiCalc[];
  realTimeValue?: number;
  isLoading: boolean;
}

const EMPTY_RTV = "-";

const CryptoOfferingCoin: FC<Props> = ({
  id,
  title,
  description,
  bgImg,
  chartData,
  multiCalcData,
  realTimeValue,
  isLoading,
}) => {
  const classes = useStyles();
  const [rtv, setRtv] = useState<string>(EMPTY_RTV);

  const chartValues = useMemo(
    () => chartData.map((item) => item[1]),
    [chartData]
  );
  const specificReturnValue =
    formatPercentage(multiCalcData[0]?.["1Y"]) ?? EMPTY_RTV;
  const specificVolatilityValue =
    formatPercentage(multiCalcData?.[3]?.["1Y"]) ?? EMPTY_RTV;
  const isNumericRealtimeValue = isNumeric(realTimeValue);

  useEffect(() => {
    if (realTimeValue !== undefined) {
      setRtv(
        isNumericRealtimeValue
          ? (realTimeValue as number).toFixed(2)
          : EMPTY_RTV
      );
    }
  }, [isNumericRealtimeValue, realTimeValue]);

  useEffect(() => {
    setRtv(EMPTY_RTV);
  }, [id]);

  return (
    <Box pt={4}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={6}>
          <Box className={classes.main}>
            <Box className={classes.mainTitle}>{title}</Box>
            <Box className={classes.mainTitleBorder} />
            <Box className={classes.mainDescription}>{description}</Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} display={{ xs: "none", md: "block" }}>
          <Box
            className={classes.chart}
            sx={{
              backgroundImage: `url(${bgImg || ImgBgPlaceholder})`,
            }}
          >
            <Box className={classes.chartMain}>
              <Box className={classes.chartTitle}>BITA {title} Price Index</Box>
              <Box className={classes.chartContainer}>
                <Chart
                  dataSeries={[
                    {
                      type: "line",
                      name: `BITA ${title} Price Index`,
                      data: chartData,
                      color: "#fff",
                      lineColor: "#fff",
                    },
                  ]}
                  chartOptions={{
                    chart: { backgroundColor: "transparent", height: 140 },
                    xAxis: { visible: false },
                    yAxis: {
                      visible: false,
                      min: Math.min(...chartValues),
                      max: Math.max(...chartValues),
                    },
                  }}
                  size="sm"
                  loading={isLoading}
                />
              </Box>
              <Box className={classes.chartBottomSection}>
                <Box className={classes.realTimeContainer}>
                  <Box className={classes.realTimeLabel}>Real-time</Box>
                  <Box className={classes.realtimeValue}>
                    {rtv}
                    {rtv !== EMPTY_RTV &&
                      (parseFloat(rtv) > 0 ? (
                        <ArrowDropUpIcon htmlColor="#05FF00" />
                      ) : (
                        <ArrowDropDownIcon htmlColor="#EA4D4D" />
                      ))}
                  </Box>
                </Box>
                <Box className={classes.smallBoxes}>
                  <Box className={classes.smallBox}>
                    <Box className={classes.smallBoxLabel}>1-Yr Return</Box>
                    <Box>
                      {isLoading ? (
                        <CircularProgress size={10} color="inherit" />
                      ) : (
                        specificReturnValue
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.smallBox}>
                    <Box className={classes.smallBoxLabel}>1-Yr Volatility</Box>
                    <Box>
                      {isLoading ? (
                        <CircularProgress size={10} color="inherit" />
                      ) : (
                        specificVolatilityValue
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(CryptoOfferingCoin);
