import { FC } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker as DefaultDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

interface Props {
  className?: string;
  label: string;
  showTime: boolean;
  currentValue: string | null;
  onChange: (value: string | null) => void;
}

const DateTimePicker: FC<Props> = ({
  className,
  label,
  showTime,
  currentValue,
  onChange,
}) => {
  const datePickerProps = {
    label: "",
    components: {
      OpenPickerIcon: CalendarTodayIcon,
    },
    renderInput: (params: any) => (
      <TextField
        variant="standard"
        sx={{
          "& .MuiInput-root": {
            paddingRight: "1rem",
          },
          "& .MuiInput-input": { textAlign: "center" },
        }}
        {...params}
      />
    ),
  };

  return (
    <Box
      className={className}
      sx={{
        display: { xs: "block", md: "flex" },
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          textTransform: "uppercase",
          color: "text.secondary",
          paddingX: "1rem",
        }}
      >
        {label}
      </Box>
      {showTime ? (
        <DefaultDateTimePicker
          inputFormat="yyyy-M-D h:m:s"
          value={currentValue}
          onChange={onChange}
          {...datePickerProps}
        />
      ) : (
        <DatePicker
          inputFormat="yyyy-M-D"
          value={currentValue}
          onChange={onChange}
          {...datePickerProps}
        />
      )}
    </Box>
  );
};

export default DateTimePicker;
