import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: "0 1.5rem 2rem",
    borderTopWidth: 1,
    borderTopStyle: "solid",

    [theme.breakpoints.down("sm")]: {
      padding: "0 1.5rem",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    paddingTop: "1.5rem",
    paddingBottom: "0.5rem",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  icon: {
    marginRight: "1rem",
    display: "block",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  label: {
    fontSize: 25,
    lineHeight: 1.2,
    fontWeight: 600,
  },
  content: {
    fontSize: 18,
    lineHeight: 1.2,
    paddingRight: 6,
  },
}));
