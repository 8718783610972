import imgFlowBank from "../../assets/images/clients/11_FlowBank.svg";
import imgGraniteShares from "../../assets/images/clients/12_Granites.svg";
import imgLeverageShares from "../../assets/images/clients/13_Leverage_Shares.svg";
import imgImpak from "../../assets/images/clients/14_impak.svg";
import imgMelanion from "../../assets/images/clients/14_Melanion.svg";
import imgPepperstone from "../../assets/images/clients/15_pepperstone.svg";
import imgQuantumrock from "../../assets/images/clients/16_Quantumrock.svg";
import imgRexShares from "../../assets/images/clients/17_RexShares.svg";
import imgStonex from "../../assets/images/clients/18_StoneX.svg";
import imgTematicaResearch from "../../assets/images/clients/19_Tematica.svg";
import imgSix from "../../assets/images/clients/1_SIX_Group_logo.svg";
import imgPlus500 from "../../assets/images/clients/2_Plus500.svg";
import imgIG from "../../assets/images/clients/3_IG.svg";
import imgGlobalX from "../../assets/images/clients/4_GlobalX.svg";
import imgCirca5000 from "../../assets/images/clients/5_Circa5000.svg";
import imgXM from "../../assets/images/clients/6_XM.svg";
import imgTMX from "../../assets/images/clients/7_tmx.svg";
import imgETF from "../../assets/images/clients/8_ETFdotCom.svg";
import imgAVATrade from "../../assets/images/clients/9_Avatrade.svg";
import imgIClima from "../../assets/images/clients/9_iclima.svg";

const CLIENTS_LIST = [
  {
    id: 0,
    img: imgSix,
    clientName: 'SIX Group AG',
    text: "A financial service provider offering infrastructure services for the Swiss financial center, focusing on securities, payment services, and financial information.",
  },
  {
    id: 1,
    img: imgPlus500,
    clientName: 'Plus 500',
    text: "A leading online service provider for trading CFDs internationally, offering a range of financial products including Forex, stocks, and commodities.",
  },
  {
    id: 2,
    img: imgGlobalX,
    clientName: 'Global X ETFs',
    text: "A provider of exchange-traded funds (ETFs) known for its thematic investing ETF suites.",
  },
  {
    id: 3,
    img: imgTMX,
    clientName: 'TMX',
    text: "TMX Group is a Canadian financial services company operating equities, fixed income, derivatives, and energy markets exchanges, including the Toronto Stock Exchange and TSX Venture Exchange.",
  },
  {
    id: 4,
    img: imgIG,
    clientName: 'IG',
    text: "A global leader in online trading, providing fast and flexible access to over 10,000 financial markets including shares, indices, and forex.",
  },
  {
    id: 5,
    img: imgStonex,
    clientName: 'Stonex Financial Limited',
    text: "A global provider of trading and investment services, offering access to a range of financial markets.",
  },
  {
    id: 6,
    img: imgPepperstone,
    clientName: 'PepperStone Group',
    text: "An online forex and CFD broker providing trading solutions to retail and institutional investors.",
  },
  {
    id: 7,
    img: imgAVATrade,
    clientName: 'AVA Trade',
    text: "An online forex and CFDs broker providing trading services and platforms to retail and institutional clients worldwide.",
  },
  {
    id: 8,
    img: imgXM,
    clientName: 'XM Global Limited',
    text: "An online trading provider, offering trading in forex, CFDs, and futures through the MT4/MT5 platforms.",
  },
  {
    id: 9,
    img: imgIClima,
    clientName: 'iClima Earth',
    text: "Pioneers in ESG 3.0 by creating impact-focused equity indices that promote regeneration and decarbonization.",
  },
  {
    id: 10,
    img: imgCirca5000,
    clientName: 'Circa5000',
    text: "Circa 5000 is a market-leading European issuer of impact ETFs focused on sustainable investing and channeling investments towards companies addressing pressing environmental and social challenges.",
  },
  {
    id: 11,
    img: imgETF,
    clientName: 'ETF.com',
    text: "A leading financial media company focused on providing news, analysis, and educational resources to the ETF community.",
  },
  {
    id: 12,
    img: imgGraniteShares,
    clientName: 'Granite Shares',
    text: "An independent ETF provider focused on providing unique, high conviction investment ideas to the market.",
  },
  {
    id: 13,
    img: imgMelanion,
    clientName: 'Melanion Capital',
    text: "Melanion Capital is a research-driven investment management firm, with a focus on alternative and quantitative investments.",
  },
  {
    id: 14,
    img: imgImpak,
    clientName: 'impak Ratings, Inc (impak Finance, Inc)',
    text: "impak specializes in financial data analytics and machine learning to enhance investment decisions and risk management.",
  },
  {
    id: 15,
    img: imgLeverageShares,
    clientName: 'Leverage Shares',
    text: "Leverage Shares is a European leader in leveraged and inverse exchange traded products (ETPs), offering traders magnified exposure and efficient leveraged stock trading through physically replicated leveraged and inverse ETPs, with a mission to democratize investing access.",
  },
  {
    id: 16,
    img: imgFlowBank,
    clientName: 'Flowbank S.A. (LCG)',
    text: "FlowBank is an online bank that connects people to global markets.",
  },
  {
    id: 17,
    img: imgTematicaResearch,
    clientName: 'Tematica Research',
    text: "Tematica Research provides equity research and investment ideas based on thematic investing to financial advisors and self-directed investors, focusing on sustainable market shifts driven by global factors.",
  },
  {
    id: 18,
    img: imgRexShares,
    clientName: 'Rex Shares',
    text: "REX Shares is an innovative ETF provider specializing in alternative-strategy ETFs, ETNs, leveraged and inverse trading products like MicroSectors and T-REX, as well as option-based income strategies.",
  },
  {
    id: 19,
    img: imgQuantumrock,
    clientName: 'QuantumRock Gmbh',
    text: "quantumrock has been a dynamic presence in the financial industry, dedicated to uncovering reliable sources of alpha for their clients.",
  },
];

export { CLIENTS_LIST };
