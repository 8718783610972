import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== "dense" &&
    prop !== "nested" &&
    prop !== "subTableTitle" &&
    prop !== "textAlign" &&
    prop !== "sortable" &&
    prop !== "headerHeight",
})<{
  dense?: boolean;
  nested?: boolean;
  subTableTitle?: boolean;
  index?: number;
  headerHeight?: number | string;
  textAlign?: string;
  sortable?: boolean;
}>(
  ({
    theme,
    dense,
    nested,
    subTableTitle,
    index,
    width,
    headerHeight = "auto",
    textAlign = "left",
    sortable = false,
  }) => ({
    [`&.${tableCellClasses.head}`]: {
      background: nested ? "#E2EAFD" : "#25246D",
      color: nested ? (subTableTitle ? "#25246D" : "#616870") : "#efefef",
      height: nested && subTableTitle ? "4rem" : headerHeight,
      minWidth: nested && subTableTitle ? (index === 0 ? 120 : width) : "auto",
      fontWeight: 600,
      paddingTop: nested
        ? theme.spacing(1)
        : dense
        ? theme.spacing(2)
        : theme.spacing(3),
      paddingBottom: nested
        ? theme.spacing(1)
        : dense
        ? theme.spacing(2)
        : theme.spacing(3),
      paddingLeft: nested ? theme.spacing(1) : theme.spacing(2),
      paddingRight: nested ? theme.spacing(1) : theme.spacing(2),
      borderTop: nested ? "1px solid #25246D" : "none",
      borderBottom: nested ? "1px solid #25246D" : "none",
      textTransform: "uppercase",
      lineHeight: 1.2,
      textAlign,

      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        minWidth: nested && subTableTitle ? (index === 0 ? 80 : width) : "auto",
      },

      "& .MuiTableSortLabel-root.Mui-active": {
        color: "#efefef",
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "1rem",
      paddingTop: dense ? theme.spacing(1) : theme.spacing(4.5),
      paddingBottom: dense ? theme.spacing(1) : theme.spacing(4.5),
      paddingLeft: nested ? theme.spacing(1) : theme.spacing(2),
      paddingRight: nested
        ? theme.spacing(1)
        : sortable && textAlign === "right"
        ? theme.spacing(5)
        : theme.spacing(2),
      borderColor: theme.palette.primary.main,
      minWidth: nested && subTableTitle ? (index === 0 ? 120 : width) : "auto",
      lineHeight: 1.2,
      textAlign,

      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        minWidth: nested && subTableTitle ? (index === 0 ? 80 : width) : "auto",
      },
    },
    [`&.${tableCellClasses.sizeSmall}`]: {
      fontSize: "13px",
    },
  })
);

export default StyledTableCell;
