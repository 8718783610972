import axios from "axios";

export const API_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOnsiaWQiOiI4IiwiYWNjZXNzIjp0cnVlLCJ0eXBlIjoiZnVsbCIsImxpbWl0IjowLCJlbWFpbCI6ZmFsc2UsIm5hbWUiOiJXZWJwYWdlIFVzZXIiLCJjb21wYW55IjoiQml0YSIsInVzZXJuYW1lIjoicGlua21hbiJ9LCJuYW1lIjoiSm9obiBEb2UiLCJpYXQiOjE1MTYyMzkwMjJ9.58edQrsWLB51CR-gmcVF80QoieIe-Dc6ZzVvUL5-ckU";

export const socketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}?api_key=${API_TOKEN}`;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:3000",
});

api.defaults.headers.common["Authorization"] = `Bearer ${API_TOKEN}`;

export const analyzerApi = axios.create({
  baseURL: process.env.REACT_APP_ANALYZER_API_URL ?? "http://localhost:3000",
});

export default api;
