import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "isOdd",
})<{ isOdd?: boolean; dense?: boolean }>(({ theme, isOdd, dense }) => ({
  backgroundColor: theme.palette.common.white,
  paddingTop: dense ? theme.spacing(1) : theme.spacing(4.5),
  paddingBottom: dense ? theme.spacing(1) : theme.spacing(4.5),

  "&:nth-of-type(odd)": {
    backgroundColor: isOdd
      ? theme.palette.action.hover
      : theme.palette.common.white,
  },
  "&:first-of-type td, &:first-of-type th": {
    borderTopWidth: 0,
  },
}));

export default StyledTableRow;
