import { useEffect, useState } from "react";
import { Box, Container, Grid, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import IconLogo from "../../assets/images/icons/Logo_simple.svg";
import {
  PageColorScheme,
  PageMainContentColorScheme,
  PageType,
} from "../../types/ui";
import ContactUs from "../ContactUs";
import SocialLinks from "../SocialLinks";
import { ITEMS } from "./menuitems";
import FooterMobileMenu from "./MobileMenu";
import { useStyles } from "./styles";

interface Props {
  colorScheme: PageColorScheme;
  mainContentColorScheme: PageMainContentColorScheme;
  contactType?: PageType;
  noContactUs?: boolean;
}

const COLOR_MAP: {
  [key in PageColorScheme]: { [key in PageMainContentColorScheme]: string[] };
} = {
  blue: { light: ["#235CFF"], dark: ["#235CFF", "#25246D"] },
  dark_blue: { light: ["#25246D", "#4C4AAD"], dark: ["#25246D", "#1B1A4F"] },
  light_blue: { light: ["#1891FF", "#5DB2FF"], dark: ["#1995FF", "#136BBB"] },
  gray: { light: ["#3B3F44", "#4E5663"], dark: ["#3F4449", "#34383E"] },
  light_gray: { light: [""], dark: [""] },
  green: { light: [""], dark: ["#016C53", "#075643"] },
  purple: { light: [""], dark: ["#5E2B91", "#432A5B"] },
  home: { light: [], dark: ["#011948", "#11275B"] },
};

const Footer: React.FC<Props> = ({
  colorScheme,
  mainContentColorScheme,
  contactType = "contact",
  noContactUs = false,
}) => {
  const [currentYear, setCurrentYear] = useState<string>("2022");

  const classes = useStyles();

  const colors = COLOR_MAP[colorScheme][mainContentColorScheme];

  useEffect(() => {
    const date = new Date();
    setCurrentYear(date.getFullYear().toString());
  }, []);

  return (
    <Box className="footer">
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box
          className={classes.mobileRoot}
          sx={{
            background: colors[0],
            "&::before": {
              background: colors[1],
            },
          }}
        >
          {!noContactUs && <ContactUs type={contactType} color={colors[0]} />}

          <Box py={{ xs: 3, md: 6 }}>
            <FooterMobileMenu items={ITEMS} />
          </Box>

          <Box>
            <Box className={classes.mobileFollowUs}>Follow Us!</Box>
            <SocialLinks inMobile />
          </Box>
          <Box pt={4} textAlign="center">
            <Box className={classes.logoWrapper}>
              <img
                className={classes.mobileLogo}
                src={IconLogo}
                alt="BITA Logo"
              />
            </Box>
            <Box className={classes.copyright}>
              Copyright © 2018-{currentYear} · BITA GmbH
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Box
          className={classes.root}
          sx={{
            background: colors[0],
            "&::before": {
              background: colors[1],
            },
          }}
        >
          {!noContactUs && <ContactUs type={contactType} color={colors[0]} />}
          <Container>
            <Grid className={classes.container} container>
              <Grid item xs={12} sm={2}>
                <Box className={classes.logoWrapper}>
                  <img src={IconLogo} alt="BITA Logo" />
                </Box>
              </Grid>

              <Grid item xs={12} sm={10}>
                <Box className={classes.main}>
                  {ITEMS.map(({ id, label, items, hidden }) =>
                    !hidden ? (
                      <Box key={id} className={classes.navSection}>
                        <Box>
                          <Box className={classes.title}>{label}</Box>
                        </Box>
                        <Box className={classes.navList}>
                          {items?.map(
                            ({ id, label, href, hidden, target = "_self" }) =>
                              !hidden && href ? (
                                <Box key={id} className={classes.navItem}>
                                  {href.startsWith("https://") ||
                                  href.startsWith("http://") ? (
                                    <MuiLink
                                      className={classes.link}
                                      href={href}
                                    >
                                      {label}
                                    </MuiLink>
                                  ) : (
                                    <Link
                                      className={classes.link}
                                      to={href}
                                      target={target}
                                    >
                                      {label}
                                    </Link>
                                  )}
                                </Box>
                              ) : null
                          )}
                        </Box>
                      </Box>
                    ) : null
                  )}
                </Box>
              </Grid>
            </Grid>

            <Box pt={2.5}>
              <SocialLinks />
            </Box>

            <Box className={classes.copyright}>
              Copyright © 2018-{currentYear} · BITA GmbH
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
