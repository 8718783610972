import qs from "qs";
import { AuthResponse } from "../../types";
import { MultiCalcResponse } from "../../types/analyzer";
import { analyzerApi } from "../api";

export const authAnalyzer = async (
  username: string,
  password: string
): Promise<AuthResponse> => {
  const { data } = await analyzerApi.post(
    "/api/v1/auth/token",
    qs.stringify({
      username,
      password,
    }),
    {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    }
  );
  return data;
};

// old implementation of multi_calculation api
export const getMultiCalcLegacy = async (
  token: string,
  input: { [key: string]: number } = {}
): Promise<MultiCalcResponse | null> => {
  const dates = Object.keys(input);
  if (!dates.length) return null;
  const { data } = await analyzerApi.post(
    "/api/v1/calculation/multi_calculation",
    {
      calculus_definitions: [
        {
          func: "specific_returns",
          index_id: -1,
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "annualized_returns",
          index_id: -1,
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "annualized_sharpe_ratio",
          index_id: -1,
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "specific_volatility",
          index_id: -1,
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "annualized_specific_volatility",
          index_id: -1,
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "max_drawdown",
          index_id: -1,
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
      ],
      custom_data: [
        {
          field_id: -1,
          format: "time_series",
          field_type: "index_values",
          data: input,
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getMultiCalc = async (
  token: string,
  input: { [key: string]: number } = {}
): Promise<MultiCalcResponse | null> => {
  const dates = Object.keys(input);
  if (!dates.length) return null;

  const { data } = await analyzerApi.post(
    "/api/v1/calculation/multi_calculation",
    {
      calculus_definitions: [
        {
          func: "specific_returns",
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "annualized_returns",
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "annualized_sharpe_ratio",
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "specific_volatility",
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "annualized_specific_volatility",
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
        {
          func: "max_drawdown",
          time_periods: ["YTD", "1Y", "3Y", "5Y", "max"],
          start_date: dates[0],
          end_date: dates[dates.length - 1],
        },
      ],
      index: {
        index_type: "custom",
        index_values: input,
      },
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};
