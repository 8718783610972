import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {
    background: "#1B1B1B",
    color: "#fff",
  },
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  text: {
    maxWidth: theme.spacing(90),
    fontSize: 20,
    lineHeight: 1.2,
  },
  main: {
    padding: "6rem 0",
  },
}));
