import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";

interface Props {
  title: string;
  description: string;
  items?: string[];
  bgColor: string;
}

const BitaCoreIndexCard: React.FC<Props> = ({
  title,
  description,
  items,
  bgColor,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.wrapper}
      sx={{
        height: { xs: "auto", md: "100%" },
        margin: { xs: "0.75rem 0", md: 0 },
        backgroundColor: bgColor,
      }}
    >
      <Box>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.description}>{description}</Box>
        {items && (
          <Box className={classes.listWrapper}>
            <ul className={classes.list}>
              {items.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </Box>
        )}
      </Box>
      <Box className={classes.learnMore}>
        <ChevronRightIcon fontSize="small" />
        <Link to="/contact-us" target="_blank">
          Learn More
        </Link>
      </Box>
    </Box>
  );
};

export default BitaCoreIndexCard;
