import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
  },
  content: {
    maxWidth: "60rem",
    margin: "0 auto",
    padding: "2.5rem 2rem",
    textAlign: "center",
    color: "#fff",

    [theme.breakpoints.down("md")]: {
      padding: "1.5rem 0",
    },
  },
  title: {
    fontSize: "3.125rem",
    lineHeight: 1.2,
    fontWeight: 600,
  },
  body: {
    paddingTop: "1.5rem",
    paddingBottom: "4.5rem",
    fontSize: 25,
    lineHeight: 1.4,
  },
  button: {
    "& .MuiButton-root": {
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
}));
