import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingX: "1rem",
    paddingBottom: "1rem",
    "& .MuiInput-underline": {
      paddingLeft: "1.25rem",
      paddingRight: "1.25rem",
    },
    "& .MuiSelect-icon": {
      right: "0.75rem",
    },

    [theme.breakpoints.down("sm")]: {
      paddingX: 0,
    },
  },
  formControl: {
    width: "100%",
  },
  menuItem: {
    backgroundColor: "#fff",
    "&:hover": { backgroundColor: "#1684FF30" },
  },
}));
