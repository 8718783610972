import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  main: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  mainHeading: {
    ...pageStyles(theme).mainHeading,
    fontSize: 40,
    color: theme.palette.primary.main,
  },
  mainText: {
    fontSize: 25,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),

    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  text: {
    fontSize: 20,
    lineHeight: 1.2,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),

    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  sectionBorder: {
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.primary.main,
  },
}));
