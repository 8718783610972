import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {
    backgroundColor: "#1b1b1b",

    "& .slick-prev": {
      color: "#BB29FF",

      "& svg": {
        fill: "#832785",
        stroke: "#832785",
      },

      "&:hover": {
        color: "#D1A3FF",
        "& svg": {
          fill: "#fff",
          stroke: "#fff",
        },
      },
    },

    "& .slick-next": {
      color: "#BB29FF",

      "& svg": {
        fill: "#832785",
        stroke: "#832785",
      },

      "&:hover": {
        color: "#D1A3FF",
        "& svg": {
          fill: "#fff",
          stroke: "#fff",
        },
      },
    },
  },
  initial: {},
  headerBg: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: "30vh",
    background:
      "linear-gradient(180deg, rgba(126, 42, 151, 0) 0%, #1B1B1B 88.02%)",
  },
  hero: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "5.5rem",
    paddingBottom: "1rem",
  },
  divider: {
    width: "15rem",
    borderTop: "1px solid #fff",
    margin: "2rem 0",
  },
  text: {
    maxWidth: theme.spacing(90),
    fontSize: 20,
    lineHeight: 1.2,
  },
  main: {
    padding: "2rem 0",
  },
  mainTopSection: {
    padding: "6rem 0 0",
  },
  heroText: {
    fontSize: 25,
    lineHeight: 1.2,
  },
  topSectionHeading: {
    fontSize: 35,
    fontWeight: 600,
  },
  topSectionSubHeading: {
    fontSize: 23,
    fontWeight: 400,
  },
  sectionContainer: {
    padding: "0 0 1rem",
  },
  heroSection: {
    width: "100%",
    margin: "6rem 0",
    color: "#fff",

    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
  },
  heroInnerContainer: {
    paddingTop: "9.5rem",
    paddingBottom: "9.5rem",
    paddingLeft: "9.5rem",
    marginLeft: "-9.5rem",
    marginRight: "-9.5rem",
    width: "calc(100% + 20vw)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
      borderTop: "1px solid #fff",
      borderBottom: "1px solid #fff",
    },
  },
  heroMainHeading: {
    maxWidth: "30rem",
  },
  heroMainText: {
    maxWidth: "25rem",
  },
}));
