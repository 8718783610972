import Img0 from "../../assets/images/home/news/0.png";
import Img1 from "../../assets/images/home/news/1.png";
import Img10 from "../../assets/images/home/news/10.png";
import Img2 from "../../assets/images/home/news/2.png";
import Img3 from "../../assets/images/home/news/3.png";
import Img4 from "../../assets/images/home/news/4.png";
import Img5 from "../../assets/images/home/news/5.png";
import Img6 from "../../assets/images/home/news/6.png";
import Img7 from "../../assets/images/home/news/7.png";
import Img8 from "../../assets/images/home/news/8.png";
import Img9 from "../../assets/images/home/news/9.png";
import { WebStatus } from "../../types/indexes";

export const NEWS_ITEMS = [
  {
    id_m_web_news: 2023042,
    createdate: "2023-04-13T15:44:33.084Z",
    title: "Decoding the EU Taxonomy: A Primer for Institutional Investors",
    link_media:
      "https://bita.ghost.io/decoding-the-eu-taxonomy-a-primer-for-institutional-investors-background/",
    preview_image: Img10,
    status: "A" as WebStatus,
    categorization: {
      name: "NEW BLOG",
    },
  },
  {
    id_m_web_news: 202304,
    createdate: "2023-04-13T15:44:33.084Z",
    title: "BITA Indexes Power Plus500's New ESG Tranding Suite",
    link_media:
      "https://bita.ghost.io/bita-indexes-power-plus500s-new-esg-trading-suite/",
    preview_image: Img9,
    status: "A" as WebStatus,
    categorization: {
      name: "ANNOUNCEMENT",
    },
  },
  {
    id_m_web_news: 2023032,
    createdate: "2023-03-13T15:44:33.084Z",
    title:
      "BITA Supports the Future Generation of Finance Professionals at Rutgers University",
    link_media:
      "https://bita.ghost.io/bita-supports-the-future-generation-of-finance-professionals-at-rutgers-university-2/",
    preview_image: Img8,
    status: "A" as WebStatus,
    categorization: {
      name: "ANNOUNCEMENT",
    },
  },
  {
    id_m_web_news: 202303,
    createdate: "2023-03-13T15:44:33.084Z",
    title:
      "The Lithium Industry: A Beneficiary of the Electric Vehicle Boom and the Energy Transition",
    link_media:
      "https://bita.ghost.io/the-lithium-industry-a-beneficiary-of-the-electric-vehicle-boom-and-the-energy-transition/?utm_source=linkedin&utm_medium=social&utm_campaign=lithium_post",
    preview_image: Img0,
    status: "A" as WebStatus,
    categorization: {
      name: "NEW BLOG",
    },
  },
  {
    id_m_web_news: 2022112,
    createdate: "2022-11-13T15:44:33.084Z",
    title:
      "Track the environmental footprint of your investment portfolios with new environmental impact reports.",
    link_media:
      "https://www.linkedin.com/feed/update/urn:li:activity:7008820053521395712",
    preview_image: Img1,
    status: "A" as WebStatus,
    categorization: {
      name: "NEW PRODUCT",
    },
  },
  {
    id_m_web_news: 202211,
    createdate: "2022-11-13T15:44:33.084Z",
    title:
      "The World’s Most Granular Thematic Database just got bigger with 15 new thematics!",
    link_media: "/products/thematic-data",
    preview_image: Img2,
    status: "A" as WebStatus,
    categorization: {
      name: "NEW PRODUCT",
    },
  },
  {
    id_m_web_news: 202210,
    createdate: "2022-10-13T15:44:33.084Z",
    title:
      "Our partners from Global X ETFs AU launch their Global X Green Metal Miners ETF! ",
    link_media:
      "https://www.linkedin.com/feed/update/urn:li:activity:6991062073241677824",
    preview_image: Img3,
    status: "A" as WebStatus,
    categorization: {
      name: "ETF ANNOUNCEMENT",
    },
  },
  {
    id_m_web_news: 202209,
    createdate: "2022-09-13T15:44:33.084Z",
    title:
      "BITA iCLIMA launch a range of environmental-impact indexing, data and analytics solutions",
    link_media:
      "https://www.linkedin.com/feed/update/urn:li:activity:6977929381268570113",
    preview_image: Img4,
    status: "A" as WebStatus,
    categorization: {
      name: "PARTNERSHIP",
    },
  },
  {
    id_m_web_news: 2022082,
    createdate: "2022-08-13T15:44:33.084Z",
    title: "2ndVote Advisers to leverage BITA’s direct-indexing platform",
    link_media:
      "https://www.einnews.com/pr_news/588532925/2ndvote-to-leverage-bita-s-direct-indexing-platform-to-develop-innovative-investment-products",
    preview_image: Img5,
    status: "A" as WebStatus,
    categorization: {
      name: "ANNOUCEMENT",
    },
  },
  {
    id_m_web_news: 202208,
    createdate: "2022-08-13T15:44:33.084Z",
    title: "Meet Nicolas Ameline, our new MD of Sales and Partnerships.",
    link_media:
      "https://www.linkedin.com/feed/update/urn:li:activity:6960572552431714304",
    preview_image: Img6,
    status: "A" as WebStatus,
    categorization: {
      name: "NEW HIRE",
    },
  },
  {
    id_m_web_news: 202206,
    createdate: "2022-06-13T15:44:33.084Z",
    title: "Meet our new Chief Product Officer, Leslie Nicholson.",
    link_media:
      "https://www.linkedin.com/feed/update/urn:li:activity:6947179316250066944",
    preview_image: Img7,
    status: "A" as WebStatus,
    categorization: {
      name: "NEW HIRE",
    },
  },
];
