import { IMenuItem } from "../../types/ui";
import { checkFeatureFlagEnabled } from "../../utils/env-flgs";

export const ITEMS: IMenuItem[] = [
  {
    id: "company",
    label: "Company",
    items: [
      {
        id: "about",
        label: "About BITA",
        href: "/about",
        hidden: !checkFeatureFlagEnabled("ABOUT_US_PAGE_ENABLED"),
      },
      {
        id: "clients",
        label: "Our Clients",
        href: "/clients",
        hidden: !checkFeatureFlagEnabled("CLIENTS_PAGE_ENABLED"),
      },
      {
        id: "governance",
        label: "Governance",
        href: "/governance",
      },
      {
        id: "careers",
        label: "Careers",
        href: "https://boards.briohr.com/public/boards/bitadatagmbh-buwzva5sx8",
      },
    ],
  },
  {
    id: "indexing",
    label: "Indexing",
    items: [
      {
        id: "indexes",
        label: "BITA Indexes",
        href: "/index",
      },
      {
        id: "indexlab",
        label: "Index Lab",
        href: "/products/index-lab",
        hidden: !checkFeatureFlagEnabled("INDEX_LAB_PAGE_ENABLED"),
      },
      {
        id: "bitacore",
        label: "BITACore",
        href: "/products",
      },
      {
        id: "indexservices",
        label: "Index Services",
        href: "/index-services",
      },
      {
        id: "portfolioapis",
        label: "Indexing APIs",
        href: "/indexing-apis",
      },
      {
        id: "crypto",
        label: "Crypto Offering",
        href: "/products/crypto-offering",
        hidden: !checkFeatureFlagEnabled("CRYPTO_OFFERING_PAGE_ENABLED"),
      },
      {
        id: "announcements",
        label: "Index Announcements",
        href: "/announcements",
        hidden: !checkFeatureFlagEnabled("INDEX_LAB_PAGE_ENABLED"),
      },
    ],
  },
  {
    id: "data",
    label: "Data",
    items: [
      {
        id: "thematics",
        label: "Thematics",
        href: "/products/thematic-data",
      },
      {
        id: "esgimpact",
        label: "ESG & Impact",
        href: "/products/esg-impact-data",
      },
    ],
  },
  {
    id: "connect",
    label: "Connect",
    items: [
      {
        id: "blog",
        label: "Blog",
        href: "https://bita.ghost.io",
      },
      {
        id: "linkedin",
        label: "Linkedin",
        href: "https://www.linkedin.com/company/bita-gmbh",
      },
      {
        id: "twitter",
        label: "Twitter",
        href: "https://twitter.com/bitadata",
      },
      {
        id: "contactus",
        label: "Contact Us",
        href: "/contact-us",
      },
      {
        id: "newsletter",
        label: "Newsletter",
        href: "#",
        hidden: true,
      },
    ],
  },
  {
    id: "lagel",
    label: "Legal & Privacy",
    items: [
      {
        id: "cooliespolicy",
        label: "Cookies Policy",
        href: "/cookie-policy",
      },
      {
        id: "termsandconditions",
        label: "Terms & Conditions",
        href: "/terms-of-use",
      },
      {
        id: "disclaimer",
        label: "Disclaimer",
        href: "/disclaimer",
      },
      {
        id: "imprint",
        label: "Imprint",
        href: "/imprint",
      },
      {
        id: "privacypolicy",
        label: "Privacy Policy",
        href: "/pdfs/BITA_DPP_Policy.pdf",
        target: "_blank",
      },
    ],
  },
  {
    id: "users",
    label: "Users",
    hidden: true,
    items: [
      {
        id: "privatedashboard",
        label: "Private Dashboard",
        href: "#",
      },
      {
        id: "controlpanel",
        label: "Control Panel",
        href: "#",
      },
    ],
  },
];
