import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    paddingLeft: "2.5rem",
    fontSize: 18,
    lineHeight: "30px",
  },
  imgWrapper: {
    position: "absolute",
    top: "0.25rem",
    left: "0.5rem",
  },
  title: {
    fontWeight: 700,
  },
}));
