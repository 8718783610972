import { makeStyles } from "@mui/styles";
import theme from "../../theme";

export const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: 25,
    fontWeight: 600,
  },
  searchInput: {},
  item: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    overflow: "hidden",
    cursor: "pointer",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: "4rem",
    borderColor: "transparent",
  },
  selected: {
    borderColor: "#fff",
  },
});
