import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {
    position: "relative",
    color: "#fff",
  },
  hero: {
    height: "100%",
  },
  heroWrapper: {
    height: "calc(80vh - 10rem)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  heroSubTitle: {
    fontSize: 25,
  },
  heroBottomBg: {
    zIndex: 1,
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "30vh",
    background:
      "linear-gradient(180deg, rgba(72, 96, 124, 0) 0%, rgba(2, 5, 9, 0.244683) 23.96%, rgba(0, 0, 0, 0.572917) 50%, rgba(3, 6, 9, 0.804253) 77.08%, #0D1018 100%)",
  },
  mainContainer: {
    zIndex: 1,
    position: "relative",
  },
  main: {
    // position: "relative",
    padding: "2rem 0 6rem",
    backgroundColor: "#0D1018",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  mainContent: {
    paddingRight: "6rem",

    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
  mainTitle: {
    fontSize: 40,
    fontWeight: 600,
    paddingTop: "4rem",
    paddingBottom: "0.75rem",

    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
  },
  mainTitleTopLine: {
    backgroundColor: "#698BEC",
    width: "3rem",
    height: 2,
    marginBottom: "0.5rem",
  },
  mainDescription: {
    fontSize: "1.25rem",

    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      paddingBottom: "5rem",
    },
  },
  movingCircle: {
    position: "absolute",
    width: "15rem",
    height: "15rem",
    borderRadius: "100%",
    background: "linear-gradient(360deg, #31BAFF 0%, #1684FF 100%)",
    filter: "blur(40px)",
    zIndex: 0,
    transform: "translate(-50%, -50%)",
  },
  imgWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileBg: {
    position: "absolute",
    opacity: 0.5,
    top: 0,
    left: 0,
    width: "200%",
    bottom: 0,
    right: 0,
    backgroundSize: "cover",
    backgroundPosition: "5rem 3rem",
  },
}));
