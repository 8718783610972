import { FC } from "react";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import SearchIcon from "@mui/icons-material/Search";
import SpeedIcon from "@mui/icons-material/Speed";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Grid } from "@mui/material";
import { useIsMobileView } from "../../hooks/useWindowSize";
import Carousel from "../Carousel";
import ThematicDataCard from "../ThematicDataCard";
import { useStyles } from "./styles";

const CARDS = [
  {
    id: 0,
    icon: <CalendarViewDayIcon fontSize="large" />,
    title: ["Environmental Metrics"],
    content:
      "Coverage of more than 6,000 companies spanning across multiple analytic dimensions and more than 150 data fields.",
  },
  {
    id: 1,
    icon: <WorkIcon fontSize="large" />,
    title: ["Impact Metrics"],
    content:
      "Product and Activity level analysis of companies positive and negative impact towards achievement of the UN SDGs.",
  },
  {
    id: 2,
    icon: <SearchIcon fontSize="large" />,
    title: ["Controversial Business", "Exposure Analysis"],
    content:
      "Develop any type of ESG and low carbon investment strategy to help you to meet your sustainable objectives",
  },
  {
    id: 3,
    icon: <SpeedIcon fontSize="large" />,
    title: ["Net-Zero Commitment", "and Alignment Data"],
    content: "Analysis of companies committed to ambitious climate action.",
  },
  {
    id: 3,
    icon: <ThermostatIcon fontSize="large" />,
    title: ["Temperature Metrics"],
    content:
      "Translation of publicly reported GHG emissions into temperature scores based on emissions pathways.",
  },
];

const ESGCards: FC<{}> = () => {
  const classes = useStyles();
  const inMobile = useIsMobileView();

  if (inMobile) {
    return (
      <Box className={classes.mobileContainer}>
        <Carousel>
          {CARDS.map((card) => (
            <Box className={classes.mobileCard}>
              <Box className={classes.mobileCardIcon}>{card.icon}</Box>
              <Box className={classes.mobileCardTitle}>{card.title}</Box>
              <Box className={classes.mobileCardContent}>{card.content}</Box>
            </Box>
          ))}
        </Carousel>
      </Box>
    );
  }

  return (
    <Grid className={classes.container} container spacing={2}>
      {CARDS.map((card) => (
        <Grid key={card.id} item xs={12} sm={6}>
          <ThematicDataCard color="#fff" background="#fff" {...card} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ESGCards;
