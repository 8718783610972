import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCryptocurrencies } from "../../services/assets";
import { Cryptocurrency } from "../../types/assets";
import { RootState } from "../store";

export interface AssetsState {
  cryptocurrencies: Cryptocurrency[];
  isLoading: boolean;
  error: any;
}

const initialState: AssetsState = {
  cryptocurrencies: [],
  isLoading: false,
  error: null,
};

// Thunks
export const fetchCryptocurrencies = createAsyncThunk(
  "assets/cryptocurrencies/fetch",
  async (_, thunkAPI) => {
    try {
      const data = await getCryptocurrencies();
      return data;
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCryptocurrencies.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchCryptocurrencies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cryptocurrencies = action.payload?.data;
      state.error = null;
    });
    builder.addCase(fetchCryptocurrencies.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message ?? "";
    });
  },
});

export const selectCryptocurrencies = (state: RootState) =>
  state.assets.cryptocurrencies;

export default assetsSlice.reducer;
