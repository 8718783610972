import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    padding: "0.75rem 2rem !important",
    fontSize: "18px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    wordBreak: "keep-all",
    textTransform: "none",
    lineHeight: "22px",
  },
}));
