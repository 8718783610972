import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    minHeight: "200px",
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  main: { flex: 1 },
  city: {
    fontWeight: 600,
    fontSize: 30,
    lineHeight: 1.2,
    color: "#fff",
  },
  country: {
    paddingTop: 1,
    fontSize: 20,
    lineHeight: 1.2,
    color: "#fff",
  },
  address: {
    fontWeight: 600,
    color: "#fff",
  },
}));
