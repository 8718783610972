import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";

const PageIndex: FC<{}> = () => {
  const { id } = useParams();

  if (!id) return null;

  return <Navigate to={`/index/${id}`} />;
};

export default PageIndex;
