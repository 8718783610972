import { Box } from "@mui/material";
import HubspotForm from "react-hubspot-form";
import LoadingSpinner from "../LoadingSpinner";
import { useStyles } from "./styles";

const HubspotContactForm = () => {
  const classes = useStyles();

  return (
    <HubspotForm
      portalId={process.env.REACT_APP_HUBSPOT_PORTAL_ID}
      formId={process.env.REACT_APP_HUBSPOT_FORM_ID}
      loading={
        <Box className={classes.loadingContainer}>
          <LoadingSpinner />
        </Box>
      }
    />
  );
};

export default HubspotContactForm;
