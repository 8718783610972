import React, { FC } from "react";
import Slider, { CustomArrowProps, Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ArrowSvg = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21436 1.25789L6.18186 0.225391L0.406856 6.00039L6.18186 11.7754L7.21436 10.7429L2.47186 6.00039L7.21436 1.25789H7.21436Z"
      fill="#235CFF"
    />
  </svg>
);

const SampleNextArrow = ({ className, onClick }: CustomArrowProps) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowSvg />
    </div>
  );
};

const SamplePrevArrow = ({ className, onClick }: CustomArrowProps) => {
  return (
    <div className={className} onClick={onClick}>
      <ArrowSvg />
    </div>
  );
};

const Carousel: FC<{ children: React.ReactNode; options?: Settings }> = ({
  children,
  options,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...options,
  };

  return (
    <Slider className="bita-carousel" {...settings}>
      {children}
    </Slider>
  );
};

export default Carousel;
