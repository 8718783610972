import { FC } from "react";
import { Box, Container } from "@mui/material";
import ImgCoin from "../../assets/images/products/crypto-offering/coin.png";
import { useStyles } from "./styles";

const CryptoOfferingData: FC<{}> = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      sx={{
        backgroundImage: { md: `url(${ImgCoin})` },
      }}
    >
      <Box className={classes.mask}></Box>
      <Container>
        <Box className={classes.main}>
          <Box className={classes.title}>Crypto Data</Box>
          <Box className={classes.text}>
            BITA's Cryptocurrency Data will be available for the most widely and
            actively traded cryptocurrencies in the market and exchanges around
            the globe. Stay tuned for the upcoming updates about this product.
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default CryptoOfferingData;
