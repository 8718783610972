import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  heroContainer: {
    position: "relative",
  },
  heroImg: {
    position: "absolute",
    top: 0,
    left: 0,
    marginTop: theme.spacing(-5),
    marginLeft: theme.spacing(-20),

    [theme.breakpoints.down("lg")]: {
      position: "relative",
      marginLeft: 0,
      marginTop: theme.spacing(2),

      "& img": {
        margin: "auto",
      },
    },
  },
  heroTitle: {
    textAlign: "right",

    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
  heroText: {
    textAlign: "right",

    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
  section: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(0),
    },
  },
  sectionTitle: {
    fontSize: theme.spacing(5),
    fontWeight: 700,
    paddingBottom: theme.spacing(2.5),
  },
  sectionSubTitle: {
    fontSize: 30,
    fontWeight: 700,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  sectionText: {
    fontSize: 20,
    color: "#1B1B1B",
  },
  sectionDivider: {
    height: 2,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
  },
  hero: {
    display: "flex",
    flexDirection: "row-reverse",

    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  heroMain: {
    flex: 1,

    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(4),
    },
  },
  heroBg: {
    position: "relative",
    flex: 1,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    [theme.breakpoints.down("lg")]: {
      minHeight: "20rem",
      marginLeft: theme.spacing(-5),
      marginRight: theme.spacing(-5),
    },
  },
  heroBgGradient: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    background: "linear-gradient(270deg, #ffffff00 72.92%, #fff 100%)",

    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  intro: {
    display: "flex",

    [theme.breakpoints.down("lg")]: {
      paddingBottom: theme.spacing(4),
    },
  },
  introMain: {
    flex: 1,
  },
  introBg: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "50%",
    right: 0,
    flex: 1,
    marginTop: theme.spacing(6),
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "left top",

    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  statsSection: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
  statsItem: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  statsLabel: {
    paddingTop: theme.spacing(2),
    fontSize: 20,
    fontWeight: 700,
  },
  statsValue: {
    fontSize: 40,
    fontWeight: 700,
  },
  footer: {
    position: "relative",
    marginBottom: theme.spacing(8),

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
  },
  footerImg: {
    marginLeft: theme.spacing(-27),
    marginRight: theme.spacing(-27),

    "& img": {
      width: "100%",
    },
  },
  footerLabel: {
    position: "absolute",
    top: theme.spacing(18),
    left: "63%",
    fontSize: theme.spacing(5),
    color: "#fff",

    [theme.breakpoints.down("md")]: {
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
  footerLabelBold: {
    fontSize: 50,
    fontWeight: 700,
  },
  teamContainer: {
    paddingTop: theme.spacing(6),

    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
}));
