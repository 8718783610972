import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    position: "relative",
    backgroundColor: "transparent",
    padding: "1.5rem 0",

    "& .item": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "transparent",
      padding: "1.25rem 0",
      color: "#fff",
      borderBottom: "1px solid #fff",
    },
    "& .label": {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    "& .activeItem": {},
    "& .subItem": {
      marginTop: "0.5rem",
      padding: "0.75rem 0.5rem 0",
      color: "#fff",
    },
    "& .activeSubItem": {
      paddingBottom: "0.75rem",
      backgroundColor: "rgba(193, 207, 249, 0.5)",
    },
    "& .subLabel": {
      fontSize: 25,
      lineHeight: 1.2,
    },
  },
});
