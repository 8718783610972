import { FC, useEffect, useMemo } from "react";
import { Box, Container } from "@mui/material";
import ImgFlagshipSystem from "../../assets/images/home/flagship-system.svg";
import ImgIndexServices from "../../assets/images/home/index-services.svg";
import ImgInnovativeIndex from "../../assets/images/home/innovative-index.svg";
import ImgPortfolioApi from "../../assets/images/home/portfolio-api.svg";
import ImgThematicData from "../../assets/images/home/thematic-data.svg";
import HomeCardCarousel from "../../components/HomeCardCarousel";
import ClientsList from "../../components/HomeClientsList";
import HomeSection from "../../components/HomeSection";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { fetchNewsList, selectNewsList } from "../../redux/slices/news";
import { checkFeatureFlagEnabled } from "../../utils";
import { NEWS_ITEMS } from "./data";
import { useStyles } from "./styles";

const HomePage: FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const newsList = useAppSelector(selectNewsList);

  const NEWS_API_ENABLED = checkFeatureFlagEnabled("NEWS_API_ENABLED");

  const newsItems = useMemo(
    () => (NEWS_API_ENABLED ? newsList : NEWS_ITEMS),
    [NEWS_API_ENABLED, newsList]
  );

  useEffect(() => {
    if (NEWS_API_ENABLED) {
      dispatch(fetchNewsList());
    }
  }, [NEWS_API_ENABLED, dispatch]);

  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.hero}>
          <Box className={classes.videoWrapper}>
            <video controls={false} autoPlay muted loop playsInline>
              <source src="/videos/header.mp4" type="video/mp4" />
            </video>
          </Box>
          <Box className={classes.videoBackgroundTop} />
          <Box className={classes.videoBackground} />
          <Box className={classes.heroMain}>
            <Box>
              <Box className={classes.heroTitle}>
                The Index Technology
                <br />
                Company
              </Box>
              <Box className={classes.heroDescription}>
                Power the next generation of passive and systematic investing
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.heroSub}>
          <Box>
            <Box className={classes.heroSubTitle}>The Future of Indexing</Box>
            <Box className={classes.heroSubDescription}>
              BITA is building the world's most technologically advanced index
              platform. Our cloud-based infrastructure leverages the latest
              technologies to deliver passive strategies faster and more
              flexibly than any other indexation solution.
            </Box>
          </Box>
        </Box>

        <Box py={{ xs: 2, md: 20 }}>
          <Box className={classes.trustedBy}>Trusted by</Box>
          <ClientsList />
        </Box>

        <Box className={classes.sections}>
          <HomeSection
            subTitle="Indexing Without Boundaries"
            title="Innovative Indexes"
            text={[
              "Thanks to our flexible infrastructure and proprietary IP, we can develop and launch innovative indexes at a fraction of time.",
              "Whether the idea comes from our product development team, or is introduced to us, we make sure our clients are first to market.",
            ]}
            link="/index"
            image={ImgInnovativeIndex}
          />

          <HomeSection
            subTitle="Direct and Self-Indexing at Scale"
            title="BITACore: BITA’s flagship system"
            text={[
              "The most powerful end-to-end index and benchmarks development software. Our BITACore platform can be used by clients to customize any index or systematic strategy in minutes.",
              "Access a large variety of datasets, bring your own data, customize, backtest, and quickly generate regulatory compliant reports and analytics. All within one platform.",
            ]}
            link="/products"
            image={ImgFlagshipSystem}
            direction="reverse"
          />

          <HomeSection
            subTitle="The Home of Pure Thematics"
            title="Granular Thematic Data and Research"
            text={[
              "The world’s most complete and granular thematic database. Going all the way to products, services and activities, our thematic database allows clients to uncover and quantify the true thematic exposure of a company or portfolio.",
              "Available via data licenses, through indexes, APIs, or via our BITACore and BITALens platforms.",
            ]}
            link="/products/thematic-data"
            image={ImgThematicData}
          />

          <HomeSection
            subTitle="Complete Customization"
            title="Indexing API’s"
            text={[
              "Bring your product development, analytics, and reporting to the next level. From investment customization, backtesting, ESG and thematic analytics, to regulatory reporting, our suite of indexing APIs have you covered.",
            ]}
            link="/indexing-apis"
            image={ImgPortfolioApi}
            direction="reverse"
          />

          <HomeSection
            subTitle="Indexing as Service"
            title="Indexing and Calculation Services"
            text={[
              "BITA offers highly flexible and customizable index calculation, iNAV/iOPV calculation, index research, and index administration services.",
              "Our clients can run successful indexing businesses while we take care of infrastructure, operations and regulation.",
            ]}
            link="/index-services"
            image={ImgIndexServices}
          />
        </Box>

        {newsList?.length ? (
          <Box pt={{ xs: 0, md: 4 }} pb={12.5}>
            <Box className={classes.newsTitle}>Latest News</Box>
            <HomeCardCarousel data={newsItems} />
          </Box>
        ) : null}
      </Container>
    </Box>
  );
};

export default HomePage;
