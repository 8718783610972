export const BUSINESS_DAYS = [
  {
    id: 0,
    time: "From 3 am to 3 pm",
    contact_channel:
      "Email, phone, Slack dedicated channel, Whatsapp dedicated group.	",
    email: ["ops@bitadata.com", "jp@bita.io", "gk@bita.io"],
    phone: ["+91 9820838948", "+44 7587162136"],
  },
  {
    id: 1,
    time: "From 3 pm to 3 am",
    contact_channel:
      "Email, phone, Slack dedicated channel, Whatsapp dedicated group.",
    email: ["ops@bitadata.com", "mp@bita.io", "gk@bita.io"],
    phone: ["+58 4241319532", "+44 7587162136"],
  },
];

export const WEEKENDS = [
  {
    id: 0,
    time: "24 hours",
    contact_channel:
      "Email, phone, Slack dedicated channel, Whatsapp dedicated group.",
    email: ["ops@bitadata.com", "jp@bita.io", "gk@bita.io"],
    phone: ["+91 9820838948", "+44 7587162136"],
  },
];
