import { Box } from "@mui/material";
import { Column, RowData } from "../../types/ui";
import Table from "../Table";
import { useStyles } from "./styles";

interface SubTable {
  title: string;
  columns: Column[];
  data: RowData[];
}

interface Props {
  title: string;
  tables: SubTable[];
}

const NestedTable: React.FC<Props> = ({ title, tables }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{title}</Box>
      {tables.map(({ title, columns, data }) => (
        <Box key={title}>
          <Table
            columns={[
              {
                key: "__subtable_title__",
                label: title,
              },
              ...columns,
            ]}
            data={data}
            dense
            nested
            hasPagination={false}
          />
        </Box>
      ))}
    </Box>
  );
};

export default NestedTable;
