import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  loadingContainer: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    padding: "20rem 0",

    [theme.breakpoints.down("md")]: {
      padding: "10rem 0",
    },
  },
}));
