import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useAppSelector } from "../../hooks/useStore";
import { useIsMobileView } from "../../hooks/useWindowSize";
import {
  indexReturnsPercentCols,
  indexReturnsRawCols,
} from "../../pages/IndexDetails/table";
import { selectAnalyzerToken } from "../../redux/slices/auth";
import { selectIndexDetailEod, selectIndexHistory } from "../../redux/slices/indexes";
import { getMultiCalc, getMultiCalcLegacy } from "../../services";
import { MultiCalc } from "../../types/analyzer";
import { checkFeatureFlagEnabled } from "../../utils/env-flgs";
import { DATE_FORMAT } from "../../utils/string";
import { getMobileColumns } from "../../utils/table";
import LoadingSpinner from "../LoadingSpinner";
import NestedTable from "../NestedTable";

interface Props {
  id: string;
  disabledItemIds: string[];
}

const IndexNestedTables: FC<Props> = ({ id, disabledItemIds }) => {
  const inMobile = useIsMobileView();
  const { enqueueSnackbar } = useSnackbar();

  const analyzerToken = useAppSelector(selectAnalyzerToken);
  const eod = useAppSelector(selectIndexDetailEod);
  const indexHistory = useAppSelector(selectIndexHistory);

  const [data, setData] = useState<MultiCalc[]>([]);
  const [error, setError] = useState<any>(null);

  const indexHistoryItem = useMemo(
    () => indexHistory?.find(({ index_id }) => index_id === id),
    [id, indexHistory]
  );

  const calcInput = useMemo(() => {
    if (!id) return null;
    if (eod?.[id]?.length) {
      return eod[id].reduce((acc, item) => {
        const { timestamp, value } = item;
        return { ...acc, [moment(timestamp).format(DATE_FORMAT)]: value };
      }, {});
    }
    if (indexHistoryItem?.data?.length) {
      return indexHistoryItem?.data.reduce((acc, item) => {
        const { timestamp_real, close } = item;
        return { ...acc, [moment(timestamp_real).format(DATE_FORMAT)]: close };
      }, {});
    }
    return null;
  }, [eod, id, indexHistoryItem?.data]);

  const tableData = useMemo(() => {
    return data.map((item) => {
      const cloned: any = { ...item };
      disabledItemIds.forEach((dId) => {
        cloned[dId] = "N/A";
      });
      return cloned;
    });
  }, [data, disabledItemIds]);

  const fetchMultiCalcData = useCallback(async () => {
    try {
      if (analyzerToken && calcInput) {
        const resp = await (checkFeatureFlagEnabled("USE_NEW_ANALYZER_API")
          ? getMultiCalc(analyzerToken, calcInput)
          : getMultiCalcLegacy(analyzerToken, calcInput));
        if (resp) {
          setData(resp.result);
          setError(null);
        }
      }
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, [analyzerToken, calcInput]);

  const tableGroup1 = useMemo(
    () =>
      tableData.length > 2
        ? [
            {
              title: "Return",
              columns: inMobile
                ? getMobileColumns(indexReturnsPercentCols)
                : indexReturnsPercentCols,
              data: [tableData[0]],
            },
            {
              title: "Annualized Return",
              columns: inMobile
                ? getMobileColumns(indexReturnsPercentCols)
                : indexReturnsPercentCols,
              data: [tableData[1]],
            },
            {
              title: "Sharpe Ratio",
              columns: inMobile
                ? getMobileColumns(indexReturnsRawCols)
                : indexReturnsRawCols,
              data: [tableData[2]],
            },
          ]
        : [],
    [inMobile, tableData]
  );

  const tableGroup2 = useMemo(
    () =>
      tableData.length > 5
        ? [
            {
              title: "Daily Volatility",
              columns: inMobile
                ? getMobileColumns(indexReturnsPercentCols)
                : indexReturnsPercentCols,
              data: [tableData[3]],
            },
            {
              title: "Annualized Volatility",
              columns: inMobile
                ? getMobileColumns(indexReturnsPercentCols)
                : indexReturnsPercentCols,
              data: [tableData[4]],
            },
            {
              title: "Max. Drawdown",
              columns: inMobile
                ? getMobileColumns(indexReturnsPercentCols)
                : indexReturnsPercentCols,
              data: [tableData[5]],
            },
          ]
        : [],
    [inMobile, tableData]
  );

  useEffect(() => {
    fetchMultiCalcData();
  }, [fetchMultiCalcData]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        "Failed to fetch multi calculation data. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
          preventDuplicate: true,
        }
      );
    }
  }, [enqueueSnackbar, error]);

  if (error) {
    return null;
  }

  if (!data?.length) {
    return (
      <Box p={8}>
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Grid container spacing={4} mb={6}>
      <Grid item xs={12} sm={6}>
        <NestedTable title="Index returns" tables={tableGroup1} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NestedTable title="Index Volatility" tables={tableGroup2} />
      </Grid>
    </Grid>
  );
};

export default IndexNestedTables;
