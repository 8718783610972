import { Box } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  icon: React.ReactElement;
  title: string;
  content: string;
}

const CryptoOfferingCard: React.FC<Props> = ({ icon, title, content }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.head}>
        <Box className={classes.icon}>{icon}</Box>
        <Box className={classes.title}>{title}</Box>
      </Box>
      <Box className={classes.content}>{content}</Box>
    </Box>
  );
};

export default CryptoOfferingCard;
