import { Box, Container, Grid } from "@mui/material";
import ImgFooter from "../../assets/images/about/footer.svg";
import ImgHero from "../../assets/images/about/hero.jpeg";
import ImgLogoLg from "../../assets/images/about/logo_lg.png";
import PageHero from "../../components/PageHero";
import Team from "../../components/Team";
import { useIsMobileView } from "../../hooks/useWindowSize";
import { STATS, STORY_LINES } from "./data";
import { useStyles } from "./styles";

const AboutUs = () => {
  const classes = useStyles();

  const inMobile = useIsMobileView();

  return (
    <Box className={classes.root}>
      <PageHero
        colorScheme="dark_blue"
        title="About BITA"
        subTitle="Building the rails and roads for the next generation of investing."
      />

      <Container>
        <Box className={classes.hero}>
          <Box
            className={classes.heroBg}
            sx={{
              backgroundImage: `url(${ImgHero})`,
            }}
          >
            <Box className={classes.heroBgGradient} />
          </Box>
          <Box className={`${classes.section} ${classes.heroMain}`}>
            <Box className={classes.sectionTitle}>The Vision</Box>
            <Box className={classes.sectionText} pr={4}>
              <Box pb={4}>
                Customization of investments without limits: all the
                infrastructure, unlimited scale, in minutes, and without
                integration. A full range of APIs, tools, systems, and managed
                services. Powerful yet simple to use.
              </Box>
              <Box>
                We envision a future where the management of investments is
                tailored to any institution and individual without compromising
                efficiency. Where infrastructure becomes invisible yet
                indispensable, streamlining transparency, objectivity, and
                customization.
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <Box className={classes.statsSection}>
        <Container>
          <Grid container spacing={6}>
            {STATS.map(({ id, value, label }) => (
              <Grid item xs={12} sm={12} lg={3}>
                <Box key={id} className={classes.statsItem}>
                  <Box className={classes.statsValue}>{value}</Box>
                  <Box className={classes.statsLabel}>{label}</Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box position="relative">
        <Container>
          <Box className={classes.intro}>
            <Box className={`${classes.section} ${classes.introMain}`}>
              {STORY_LINES.map(({ id, title, text }) => (
                <Box key={id}>
                  <Box className={classes.sectionSubTitle}>{title}</Box>
                  <Box className={classes.sectionText}>{text}</Box>
                </Box>
              ))}
            </Box>
            <Box flex={{ xs: 0, md: 1 }}></Box>
          </Box>
        </Container>
        <Box
          className={classes.introBg}
          sx={{ backgroundImage: `url(${ImgLogoLg})` }}
        ></Box>
        <Box className={classes.sectionDivider} />
      </Box>

      <Container>
        <Box className={classes.section}>
          <Box className={classes.sectionTitle}>Our Team</Box>
          <Box className={classes.teamContainer}>
            <Team inMobile={inMobile} />
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Box className={classes.footerImg}>
            <img src={ImgFooter} alt="Footer thumb" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUs;
