import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { 
  store, 
  // persistor 
} from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          {/* More on CssBaseline: https://stackoverflow.com/questions/57058299/what-does-the-cssbaseline-class-do */}
          <CssBaseline />
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <SnackbarProvider maxSnack={3}>
                <App />
              </SnackbarProvider>
            </LocalizationProvider>
          </BrowserRouter>
        </ThemeProvider>
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
