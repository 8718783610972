import Brightness7SharpIcon from "@mui/icons-material/Brightness7Sharp";
import BuildIcon from "@mui/icons-material/Build";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Box, Container, Grid } from "@mui/material";
import ImgArrowList from "../../assets/images/products/arrowlist.svg";
import ImgHeroMobile from "../../assets/images/products/bitacore-hero-mobile.png"; //TODO It is pending to change the images to svg @sk.karem
import ImgHero from "../../assets/images/products/bitacore-hero.png"; //TODO It is pending to change the images to svg @sk.karem
import ImgBgUniverse from "../../assets/images/products/bitacore-pattern.svg";
import ImgPattern1 from "../../assets/images/products/rectangle.svg";
import ImgPattern2 from "../../assets/images/products/rectangle2.png";
import BitaCoreIndexCard from "../../components/BitaCoreIndexCard";
import BitaCoreListItem from "../../components/BitaCoreListItem";
import PageHero from "../../components/PageHero";
import { useStyles } from "./styles";

const ProductsPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <PageHero
        colorScheme="gray"
        mainContentColorScheme="dark"
        title="BITACore"
        subTitle="Create, build, backtest, and analyze production-ready indexes and rule-based investment strategies in a matter of minutes."
      />

      <Box className={classes.main}>
        <Container>
          <Box display={{ xs: "none", md: "block" }} py={3}>
            <img src={ImgHero} alt="Hero" width="100%" />
          </Box>
          <Box
            className={classes.mainDivider}
            display={{ xs: "none", md: "block" }}
          />
          <Box className={classes.mainHeading}>
            Direct Indexing without Boundaries
          </Box>
          <Box display={{ xs: "block", md: "none" }} pt={6} pb={3}>
            <img src={ImgHeroMobile} alt="HeroMobile" width="100%" />
          </Box>
          <Box className={classes.mainSubHeading}>
            The world’s most powerful index customization and analytics
            platform.
          </Box>
          <Box className={classes.mainText} marginTop={0}>
            Leverage a broad variety of datasets (fundamental, ESG, Impact,
            Thematic, Factor), customize investment universes, parametrize
            rulebooks, and seamlessly construct a large variety of indexes and
            investment strategies.
          </Box>
        </Container>
        <Box className={classes.construction}>
          <Container>
            <Grid container spacing={4} className={classes.constructionContent}>
              <Grid item xs={12} md={6}>
                <Box className={classes.constructionTitle}>
                  <BuildIcon />
                  <Box className={classes.boxWithUnderline}>
                    Construction
                    <Box className={classes.underline} />
                  </Box>
                </Box>
                <Box className={classes.constructionImgWrapper}>
                  <img src={ImgPattern1} alt="Pattern" width="100%" />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.list}>
                  <BitaCoreListItem
                    title="Universe Builder"
                    text="Build any type of point-in-time equity universe. Tilt, filter and create accurate exposures."
                  />
                  <BitaCoreListItem
                    title="Index Builder"
                    text="Construct standard or customized indexes by parameterizing in great detail any investment or index rulebook."
                  />
                  <BitaCoreListItem
                    title="Optimized Index Builder"
                    text="Run different types of optimized strategies given a long list of available constraints."
                  />
                  <BitaCoreListItem
                    title="Structured Index Builder"
                    text="Build “Index of Index” and multi-asset strategies from combinations of existing instruments (indexes or ETFs)."
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box>
          <Container className={classes.analysisContainer}>
            <Grid container spacing={4} flexWrap="wrap-reverse">
              <Grid item xs={12} md={6}>
                <Box className={classes.list}>
                  <BitaCoreListItem
                    title="Analyzer"
                    text="Analyze indexes. Identify sources of performance while comparing against specified benchmarks."
                  />
                  <BitaCoreListItem
                    title="Basket Calculation"
                    text="Upload a pre-constructed investment basket (constituents and weights) and quickly perform a backtest."
                  />
                  <BitaCoreListItem
                    title="Rebalancing & Reconstitution"
                    text="Rebalance your indexes in a convenient way."
                  />
                  <BitaCoreListItem
                    title="Factsheet Builder"
                    text="Upload your information and generate your factsheet in minutes."
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.constructionTitle}>
                  <Box>
                    <TimelineIcon />
                  </Box>
                  <Box className={classes.boxWithUnderline}>
                    Rebalancing & Analysis
                    <Box className={classes.underline} />
                  </Box>
                </Box>
                <Box className={classes.analysisImgWrapper}>
                  <img src={ImgPattern2} alt="Pattern2" />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className={classes.data}>
          <Container className={classes.dataContent}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box className={classes.constructionTitle}>
                  <Brightness7SharpIcon />
                  <Box className={classes.boxWithUnderline}>
                    Bring your own Data:
                    <Box className={classes.underline} />
                  </Box>
                </Box>
                <Box mt={{ xs: 4, md: 0 }}>
                  <BitaCoreListItem
                    title="Data Upload"
                    text="Upload your own data fields, universes and benchmarks to customize your indexes."
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <img src={ImgArrowList} alt="Data Patterns" />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Container>
          <Box
            className={classes.mainDivider}
            display={{ xs: "none", md: "block" }}
          />
        </Container>

        <Box className={classes.universe}>
          <Box className={classes.universeBg}>
            <img src={ImgBgUniverse} alt="Universe Background" width="100%" />
          </Box>
          <Container className={classes.universeContentWrapper}>
            <Box
              className={classes.mainDivider}
              display={{ xs: "block", md: "none" }}
            />
            <Box className={classes.universeContent}>
              <Box className={classes.mainHeading}>
                Available on the cloud or via APIs
              </Box>
              <Box className={classes.universeText}>
                BITACore can be licensed as a web-based application or as a set
                of API’s offering the full range of functionality.
              </Box>
            </Box>
            <Box
              className={classes.mainDivider}
              display={{ xs: "block", md: "none" }}
            />
          </Container>
        </Box>

        <Box py={{ xs: 0, md: 10 }}>
          <Container>
            <Box className={classes.mainHeading} pb={{ xs: 0, md: 2.5 }}>
              Modular Architecture with Flexible Plans
            </Box>
            <Box className={classes.universeText} pb={6}>
              BITACore can be configured to meet particular client needs
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <BitaCoreIndexCard
                  title="BITACore Trial"
                  description="Discover all the capabilities of the product in a specialized license of 14-days for you and your team."
                  bgColor="#39B0F3"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BitaCoreIndexCard
                  title="BITACore"
                  description="Discover the standard capabilities of the platform with access to the:"
                  items={[
                    "Universe Builder",
                    "Index Builder",
                    "Optimized Index Builder",
                    "Structured Index Builder",
                    "Analyzer",
                  ]}
                  bgColor="#4891FF"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BitaCoreIndexCard
                  title="BITACore Premium"
                  description="Get access to all the modules and empower your experience"
                  items={[
                    "Universe Builder",
                    "Index Builder",
                    "Optimized Index Builder",
                    "Structured Index Builder",
                    "Analyzer",
                    "Basket Calculation",
                    "More",
                  ]}
                  bgColor="#39B0F3"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BitaCoreIndexCard
                  title="Custom License"
                  description="Customize your experience and decide what features you want to enable in the UI."
                  bgColor="#78AEFF"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductsPage;
